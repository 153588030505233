import React from 'react'
import { connect } from 'react-redux'
import VotingActions from '../../redux/votingRedux'
import { Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import {
  Check as CheckIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material'
import '../../styles/animista.css'

interface iProps {
  width: number
  candidate_duplicates: number[]

  removeCandidateDuplicate: (id: number) => void
  sendCandidateDuplicates: () => void
  resetCandidateDuplicates: () => void
}

function DuplicatesPopUp(props: iProps) {
  const { width, candidate_duplicates } = props

  return (
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={candidate_duplicates.length > 0}
    >
      <List sx={{ mt: 8 }}>
        <ListItem>
          <ListItemText primary="Marks another item as duplicate or send report:" />
        </ListItem>
        {candidate_duplicates.map((duplicate_id: any) => (
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="remove"
                onClick={(event) => props.removeCandidateDuplicate(duplicate_id)}
              >
                <CancelIcon />
              </IconButton>
            }
          >
            <ListItemButton onClick={(event) => {
              // @ts-ignore
              const element = document.getElementById(`item-${duplicate_id}`)
              if (element) {
                element.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                })
                element.classList.add('text-focus-in')
                setTimeout(() => {
                  element.classList.remove('text-focus-in')
                }, 1000)
              }
            }}>
              <ListItemText primary={`#${duplicate_id}`} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem>
          <ListItemButton
            disabled={candidate_duplicates.length < 2}
            onClick={(event) => props.sendCandidateDuplicates()}
          >
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary="Send" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={(event) => props.resetCandidateDuplicates()}>
            <ListItemIcon>
              <CancelIcon />
            </ListItemIcon>
            <ListItemText primary="Cancel" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}

const mapStateToProps = (state: any) => {
  return {
    candidate_duplicates: state.voting.candidate_duplicates,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeCandidateDuplicate: (id: number) => dispatch(VotingActions.removeCandidateDuplicate(id)),
    sendCandidateDuplicates: () => dispatch(VotingActions.sendCandidateDuplicates()),
    resetCandidateDuplicates: () => dispatch(VotingActions.resetCandidateDuplicates()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicatesPopUp)
