import React from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import MainLayout from './components/Layout/MainLayout'
import routes from './components/routes/index'
import Snackbar from './components/widgets/Snackbar'
import pages from './pages/index'
import { getBasename } from './utils/functions'

import './styles/App.css'

const theme = createTheme({
  palette: {
    primary: {
      light: '#66a0ff',
      main: '#0f72e6',
      dark: '#0048b3',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#e0e0e0',
      main: '#6c757d',
      dark: '#414951',
      contrastText: '#000000',
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  },
})

function App() {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={getBasename()}>
        <Switch>
          <routes.NonAuthenticatedRoute
            exact
            path="/login"
            layout={MainLayout}
            component={pages.SigninPage}
          />
          <routes.NonAuthenticatedRoute
            exact
            path="/signup"
            layout={MainLayout}
            component={pages.SignupPage}
          />
          <routes.LayoutRoute
            exact
            path="/confirm-email/:id/:token"
            layout={MainLayout}
            component={pages.ConfirmEmailPage}
          />
          <routes.LayoutRoute
            exact
            path="/password-reset/:id?/:token?/"
            layout={MainLayout}
            component={pages.PasswordResetPage}
          />
          <routes.LayoutRoute
            exact
            path="/error"
            layout={MainLayout}
            component={pages.ErrorPage}
          />
          <routes.AuthenticatedRoute
            exact
            path="/"
            layout={MainLayout}
            component={pages.DashboardPage}
          />
          <routes.VotingRoute
            exact
            path="/voting"
            title="Voting"
            layout={MainLayout}
            component={pages.VotingPage}
          />
          <routes.AuthenticatedRoute
            exact
            path="/disputes/:vote_id/"
            title="Disputes"
            layout={MainLayout}
            component={pages.DisputePage}
          />
          <routes.UserManagerRoute
            exact
            path="/manager/users"
            title="Users list"
            layout={MainLayout}
            component={pages.UsersListPage}
          />
          <routes.UserManagerRoute
            exact
            path="/manager/matching-performance"
            title="Matching performance"
            layout={MainLayout}
            component={pages.MatchingPerformancePage}
          />
          <routes.UserManagerRoute
            exact
            path="/manager/vote-history"
            title="Vote history"
            layout={MainLayout}
            component={pages.VoteHistoryPage}
          />
          <routes.UserManagerRoute
            exact
            path="/manager/performance"
            title="Performance"
            layout={MainLayout}
            component={pages.PerformancePage}
          />
          <routes.UserManagerRoute
            exact
            path="/manager/reference-evaluation-requests"
            title="Reference Evaluation Requests"
            layout={MainLayout}
            component={pages.ReferenceEvaluationRequestsPage}
          />
          <routes.DisputeManagerRoute
            exact
            path="/manager/disputes/"
            title="Disputes"
            layout={MainLayout}
            component={pages.ManageDisputePage}
          />
          <routes.ManageRoute
            exact
            path="/manager/disputes-list/"
            title="Disputes list"
            layout={MainLayout}
            component={pages.ManageDisputeListPage}
          />
          <routes.ManageRoute
            exact
            path="/settings/buckets"
            title="Batches list"
            layout={MainLayout}
            component={pages.BucketsListPage}
          />
          <routes.ManageRoute
            exact
            path="/settings/deduplication"
            title="Deduplication stat"
            layout={MainLayout}
            component={pages.DeduplicationPage}
          />
          <routes.BlackListRoute
            exact
            path="/blacklist-image/"
            title="Image Blacklisting Page"
            layout={MainLayout}
            component={pages.BlacklistImagesPage}
          />
          <Redirect to="/" />
        </Switch>
        <Snackbar />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
