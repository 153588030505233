import React from 'react';
import ImageList from '@mui/material/ImageList';
import CustomImageListItem from './ImageListItem';
import {CustomImageGalleryProps} from './Interfaces';


function CustomImageGallery({ listingId, baseListingData, selectedSimilarImages, toggleSimilarImageSelection }: CustomImageGalleryProps) {
  return (
    <div style={{ border: '2px solid gray' }}>
      {listingId && (
        <h3>
          Image Gallery for
          <a href={`http://spiders.espressoresearch.com/_admin/sourceestate/${listingId}`} target="_blank" rel="noopener noreferrer">
            {listingId}
          </a>
        </h3>
      )}

      <ImageList cols={5}>
        {Object.keys(baseListingData).map(key => (
          <CustomImageListItem
            key={key}
            withLink={!listingId}
            item={baseListingData[key]}
            selected={selectedSimilarImages.includes(key)}
            toggleSelection={() => toggleSimilarImageSelection(key)}
          />
        ))}
      </ImageList>
    </div>
  );
}

export default CustomImageGallery;
