import React, { useEffect } from 'react'
import { shallow } from 'zustand/shallow'

import {
  Box,
  Chip,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  DataGrid,
  GridCellClassFn,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { DASH, DAY_DEFAULT_RANGE } from '../../utils/constants'
import { dateToISO } from '../../utils/formatters'
import { objectLength, objectMap, validateDateRange } from '../../utils/functions'
import { UseTransactionStore } from '../../stores/UseTransactionStore'
import { TYPE_ANNOTATOR_DISPUTE, TYPE_MANAGER_DISPUTE, TYPE_VOTING } from '../../redux/transactionsRedux'

import Page from '../../components/Layout/Page'


const MIN_GRID_HEIGHT = 350;

const END_DATE = new Date()
const START_DATE = new Date()
START_DATE.setDate(END_DATE.getDate() - DAY_DEFAULT_RANGE)

interface iCellItem {
  type: number|string
  paid: number|undefined
  total: number|undefined
  quality: number|undefined
  injection: number|undefined
}

const CellItem = ({
  type,
  paid,
  total,
  quality,
  injection,
}: iCellItem) => {
  if (type === TYPE_VOTING) {
    return (
      <>
        <b title="Paid / Total votes">{paid || DASH} / {total || DASH}</b>
        {quality !== undefined && (
          <Chip
            title="Total errors %"
            size="small"
            className="pull-rt"
            label={`${(100 - quality).toFixed(2)}%`}
            color={quality >= 95 ? 'success' : quality >= 90 ? 'warning' : 'error'}
          />
        )}
        {injection !== undefined && (
          <Chip
            title="Reference errors %"
            size="small"
            className="pull-rb"
            label={`${(injection).toFixed(2)}%`}
            color={injection >= 40 ? 'error' : injection >= 20 ? 'warning' : 'success'}
          />
        )}
      </>
    )
  }
  if (type === TYPE_ANNOTATOR_DISPUTE) {
    return <b title="Won / Total disputes">{paid || DASH} / {total || DASH}</b>
  }
  if (type === TYPE_MANAGER_DISPUTE) {
    return <b title="Resolve disputes">{total || DASH}</b>
  }
  return <b title="unknown type">DASH</b>
}

const cellClassName: GridCellClassFn = (params) => {
  const data = params.row.transactions[params.field]
  let quality = data?.quality || 0

  if (params.field === 'total') {
    const count = objectLength(params.row.transactions)
    if (count) {
      objectMap(params.row.transactions, (day: any) => quality += day?.quality || 0)
      quality /= count
    }
  }

  if (quality) {
    if (quality > 0.95) return 'bg-success'
    if (quality > 0.90) return 'bg-warning'
    return 'bg-danger'
  }
  return ''
};

function PerformancePage() {
  const [gridHeight, setGridHeight] = React.useState<number>(MIN_GRID_HEIGHT)
  const [startDate, setStartDate] = React.useState<Date>(START_DATE)
  const [endDate, setEndDate] = React.useState<Date>(END_DATE)
  const [type, setType] = React.useState<number|string>(TYPE_VOTING)

  const { loading, performance_list, loadPerformance } = UseTransactionStore((state) => ({
    loading: state.loading,
    performance_list: state.performance_list,
    loadPerformance: state.loadPerformance,
  }), shallow)

  useEffect(() => {
    if (getError()) return
    if (!startDate || !endDate) return

    const filter = {
      items: [
        {
          columnField: 'created_at',
          operatorValue: '>',
          value: dateToISO(startDate),
        },
        {
          columnField: 'created_at',
          operatorValue: '<',
          value: dateToISO(endDate),
        },
        {
          columnField: 'type',
          operatorValue: '=',
          value: type,
        },
      ]
    };
    loadPerformance(filter)

    // @ts-ignore
    const newHeight = document.getElementById('layout-box').offsetHeight - 240
    if (newHeight > MIN_GRID_HEIGHT) {
      setGridHeight(newHeight)
    }
  }, [startDate, endDate, type])

  const getError = () => validateDateRange(startDate, endDate) ? true : false

  const getColumns = (): GridColDef[] => {
    let columns = [
      {
        field: 'user',
        headerName: 'User ID Name',
        type: 'number',
        minWidth: 250,
        cellClassName: 'MuiDataGrid-cell-stick-left',
        filterable: false,
        valueGetter: (params: any) => `#${params.row.user.id} ${params.row.user.first_name} ${params.row.user.last_name}`,
      },
    ]

    for (let day = new Date(startDate); day <= endDate; day.setDate(day.getDate() + 1)) {
      const column: GridColDef = {
        field: dateToISO(day) || '',
        headerName: dateToISO(day) || '',
        minWidth: 150,
        cellClassName,
        renderCell: (params: GridRenderCellParams) => {
          const data = params.row.transactions[params.field]

          return <CellItem
            type={type}
            paid={data?.match_count}
            total={data?.count_total}
            quality={data?.quality ? data?.quality * 100 : undefined}
            injection={data?.full_injection_error_rate}
          />
        },
      }

      // @ts-ignore
      columns.push(column)
    }


    const totalColumn: GridColDef = {
      field: 'total',
      headerName: 'Total',
      type: 'string',
      minWidth: 150,
      cellClassName,
      renderCell: (params: GridRenderCellParams) => {
        // @ts-ignore
        const count = objectLength(params.row.transactions)
        let paid = 0
        let total = 0
        let quality = 0
        let injection = 0

        // @ts-ignore
        objectMap(params.row.transactions, (day) => {
          paid += day?.match_count || 0
          total += day?.count_total || 0
          quality += day?.quality || 0
          injection += day?.full_injection_error_rate || 0
        })
        quality = (quality * 100 / count)
        injection = (injection / count)

        return <CellItem
          type={type}
          paid={paid}
          total={total}
          quality={(params.row.user.email && !isNaN(quality)) ? quality : undefined}
          injection={(params.row.user.email && !isNaN(quality)) ? injection : undefined}
        />
      },
    };
    // @ts-ignore
    columns.push(totalColumn)

    return columns
  }

  return (
    <Page
      className="Performance"
      title="Performance"
    >
      <Paper elevation={3} style={{ marginBottom: 24, padding: 16, justifyContent: "center" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box display="flex" flexDirection="row">
            <Box display="flex" alignItems="start">
              <Select
                value={type}
                label="Type"
                onChange={(event) => setType(event.target.value)}
              >
                <MenuItem value={TYPE_VOTING}>Voting</MenuItem>
                <MenuItem value={TYPE_MANAGER_DISPUTE}>Manage Dispute</MenuItem>
                <MenuItem value={TYPE_ANNOTATOR_DISPUTE}>Open Dispute</MenuItem>
              </Select>
            </Box>
            <Box display="flex" alignItems="center" sx={{ mx: 2 }}></Box>
            <DatePicker
              inputFormat="E dd MMM yyyy"
              label="Start date"
              value={startDate}
              onChange={(value: any) => setStartDate(value)}
              renderInput={(params: any) => <TextField
                {...params}
                error={getError()}
                helperText={validateDateRange(startDate, endDate)}
              />}
            />
            <Box display="flex" alignItems="center" sx={{ mx: 2 }}> to </Box>
            <DatePicker
              inputFormat="E dd MMM yyyy"
              label="End date"
              value={endDate}
              onChange={(value: any) => setEndDate(value)}
              renderInput={(params: any) => <TextField {...params} error={getError()} />}
            />
          </Box>
        </LocalizationProvider>

        {loading && <LinearProgress />}
        <div style={{ height: gridHeight, width: '100%' }}>
          <DataGrid
            rows={performance_list}
            getRowId={(row) => row.user.id}
            columns={getColumns()}
            loading={loading}
            rowHeight={60}
            disableColumnFilter={true}
            // styling
            sx={{mt: 2}}
            components={{
              NoRowsOverlay: () => (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  mt: 8,
                }}>
                  <Typography color="error">Any performance statistic yet</Typography>
                </Box>
              ),
            }}
          />
        </div>
      </Paper>
    </Page>
  )
}

export default PerformancePage
