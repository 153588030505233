import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { GridFilterModel, GridSortModel } from '@mui/x-data-grid'

import API from '../services/api'
import { buildUrls } from '../utils/functions'


const VIEWED_LIMIT = 150 * 3 // @see: ReferenceSettings.limit

export enum STATUS {
  New = 1,
  InProcess,
  Finished,
  Skipped = 5,
}

export interface iEvaluationRequest {
  readonly id?: number
  status?: STATUS,
  listing_id?: number
	bucket_id?: number
  dedup_listing_id?: string
  layer_listing_id?: string
  candidates?: any
  confirmed_duplicates?: number[]
  is_reference?: boolean
  votes_count?: number
  priority?: number
  is_new?: boolean
}

interface EvaluationRequestState {
  loading: boolean
  list: iEvaluationRequest[]
  viewed_ids: number[]

  load: (filter: GridFilterModel, sort: GridSortModel) => void
  update: (id: number, data: iEvaluationRequest) => void
  saveInViewed: (id: number) => void
  isViewed: (id: number) => boolean
}


export const UseEvaluationRequestStore = create<EvaluationRequestState>()(
  devtools(
    persist(
      (set, get) => ({
        loading: false,
        list: [],
        viewed_ids: [],

        load: async(filter, sort) => {
          set({ loading: true })
          try {
            const response = await API.get(buildUrls('evaluation-requests/', {
              filter,
              sort,
            }))

            if (response.ok) {
              set({ list: response.data })
            } else {
              set({ list: [] })
              console.error('!response.ok', response)
            }
          } catch (e) {
            console.log('load EvaluationRequest:', e)
          } finally {
            set({ loading: false })
          }
        },

        update: async(id, data) => {
          try {
            const response = await API.patch(`evaluation-requests/${id}/`, data)

            if (response.ok) {
              return true
            } else {
              console.error('!response.ok', response)
              return false
            }
          } catch (e) {
            console.error('update EvaluationRequest:', e)
          }
        },

        saveInViewed: (id: number) => {
          const uniqViews = new Set(get().viewed_ids)
          uniqViews.add(id)
          const slicedViews = uniqViews.size > VIEWED_LIMIT
            ? Array.from(uniqViews).slice(uniqViews.size - VIEWED_LIMIT)
            : Array.from(uniqViews)
          set({ viewed_ids: slicedViews })
        },
        isViewed: (id) => get().viewed_ids.includes(id),
      }),
      {
        name: 'evaluation-request-store',
      }
    )
  )
)
