import React from 'react'
import { Box, Link, Tooltip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Done as DoneIcon } from '@mui/icons-material'

import { iMessage, STATUS } from '../../../stores/UseMessageStore'
import { datetimeFormatter } from '../../../utils/formatters'

interface iProps {
  message: iMessage
  side: 'left'|'right'
}

function Message(props: iProps) {
  const { message, side } = props
  const isMine = side === 'right'

  const decorateLinks = (text: string) => {
    let match = text.split(/((?:http|https)?:\/\/[^\s]+)/g)

    if (match?.length) {
      return match.map(str => str.startsWith('http') ? <Link href={str} target="_blank">{str}</Link> : str)
    }
    return text
  }

  return <Box
    m={2}
    p={2}
    sx={{
      maxWidth: '80%',
      float: side,
      backgroundColor: (theme) => `${isMine ? theme.palette.primary.light : theme.palette.secondary.light}88`,
      borderRadius: 5,
    }}
  >
    <Typography sx={{ whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {decorateLinks(message.text)}
    </Typography>
    <Typography color={grey[500]} variant="caption" align="right" paragraph={true} m={0}>
      {datetimeFormatter(message.created_at)}
      {isMine && message.status >= STATUS.New && (
        <Tooltip title="Send">
          <DoneIcon sx={{ fontSize: '0.75rem' }} />
        </Tooltip>
      )}
      {isMine && message.status >= STATUS.Read && (
        <Tooltip title="Read">
          <DoneIcon sx={{ fontSize: '0.75rem' }} />
        </Tooltip>
      )}
    </Typography>
  </Box>
}

export default Message
