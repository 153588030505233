import React from 'react'
import { Typography, Grid, Box } from '@mui/material'
import useMobileService from '../../utils/mobileService'

interface iPage {
  children: React.ReactNode
  title? : string
  description? : string
  showTitleOnMobile?: boolean
  className?: string
}

export default function Page(props: iPage) {
  const isMobile = useMobileService()
  const { children, title, description, className, showTitleOnMobile} = props
  return (
    <Box flex={1} sx={{ p: 2 }}>
      <Grid container className={className || 'main'}>
        <Grid container className="header">
          <Grid item sx={{ my: '1.5rem' }}>
            {title && (showTitleOnMobile || !isMobile) &&
              <Typography
                variant="h3"
                component="h1"
                className="title"
                sx={{ fontWeight: 'medium' }}
              >
                {title}
              </Typography>
            }
            {description &&
              <Typography
                color="secondary"
                className="description"
                sx={{ fontStyle: 'italic' }}
              >
                {description}
              </Typography>
            }
          </Grid>
        </Grid>
        <Box flex={1}>{children}</Box>
      </Grid>
    </Box>
  )
}
