import apiHeader from './api-header'

export interface iResponse {
  ok: boolean,
  status: number
  data: any
}

function prepareResponse(response: Response) {
  if (response.status === 401) {
    document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    setTimeout(async () => {
      await localStorage.clear()
      window.location.href = '/login'
    }, 1000)
    throw new Error('Session has been expired')
  }

  if (response.status === 503 && window.location.pathname !== '/error') {
    window.location.href = '/error'
  }
  if (response.status >= 500) {
    throw new Error(response.statusText)
  }

  try {
    return response.json().then((data: any) => ({
      ok: response.ok,
      status: response.status,
      data: data,
    }))
  } catch (e) {
    throw new Error('ops.. Incorrect server response!')
  }
}

class API {
  static URL = '/api/'

  async post(url: string, data: any): Promise<iResponse> {
    return await fetch(API.URL+url, {
      method: 'POST',
      credentials: 'include',
      cache: 'no-cache',
      headers: apiHeader(),
      body: JSON.stringify(data),
    }).then(response => prepareResponse(response))
  }

  async patch(url: string, data: any): Promise<iResponse> {
    return await fetch(API.URL+url, {
      method: 'PATCH',
      credentials: 'include',
      cache: 'no-cache',
      headers: apiHeader(),
      body: JSON.stringify(data),
    }).then(response => prepareResponse(response))
  }

  async get(url: string): Promise<iResponse> {
    return await fetch(API.URL+url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
      headers: apiHeader(),
    }).then(response => prepareResponse(response))
  }
}

export default new API()
