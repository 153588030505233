import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateUser: ['user'],
  rememberUser: null,
  clearRememberUser: null,
  updateFetching: ['fetching'],
  updateErrors: ['errors'],
  updateCaptcha: ['isCaptcha'],
  setMessage: ['message'],
  resetAuthState: null,

  login: ['email', 'password', 'g_recaptcha_token'],
  reLogin: ['user_id'],
  loadUserBalance: null,
  signup: ['email', 'first_name', 'last_name', 'password', 'password2', 'g_recaptcha_token', 'history'],
  confirmEmail: ['id', 'token'],
  requestPasswordReset: ['email'],
  passwordReset: ['id', 'token', 'password'],
  logout: null,
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export interface iSimpleUser {
  id: number
  first_name: string
  last_name: string
  email: string
}

export interface iUser extends iSimpleUser {
  is_staff: boolean
  is_email_confirm: boolean
  email_confirm_sent_at: string
  email_confirm_token: string
  is_active: boolean
  is_ban: boolean
  date_joined: string
  permissions: {
    [key: string]: boolean
  }
  balance: number
  match_price: number
  dispute_price: number
  comment: string
}

type AuthState = {
  remember_user_id: number,
  user: iUser | null,
  fetching: boolean,
  errors: any,
  isCaptcha: boolean,
  message: string,
}

export const INITIAL_STATE = Immutable({
  remember_user_id: 0,
  user: null,
  fetching: false,
  errors: false,
  isCaptcha: false,
  message: '',
})

/* ------------- Reducers ------------- */


export const updateUser = (state: any, { user }: any) =>
  state.merge({ user })

export const rememberUser = (state: any) =>
  state.merge({ remember_user_id: state.user.id })

export const clearRememberUser = (state: any) =>
  state.merge({ remember_user_id: 0 })

export const updateFetching = (state: any, { fetching }: AuthState) =>
  state.merge({ fetching })

export const updateErrors = (state: any, { errors }: any) =>
  state.merge({ errors })

export const updateCaptcha = (state: any, { isCaptcha }: AuthState) =>
  state.merge({ isCaptcha })

export const setMessage = (state: any, { message }: AuthState) =>
  state.merge({ message })

export const resetAuthState = () =>
  INITIAL_STATE


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_AUTH_STATE]: resetAuthState,
  [Types.UPDATE_USER]: updateUser,
  [Types.REMEMBER_USER]: rememberUser,
  [Types.CLEAR_REMEMBER_USER]: clearRememberUser,
  [Types.UPDATE_ERRORS]: updateErrors,
  [Types.UPDATE_FETCHING]: updateFetching,
  [Types.UPDATE_CAPTCHA]: updateCaptcha,
  [Types.SET_MESSAGE]: setMessage,
})
