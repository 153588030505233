import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { shallow } from 'zustand/shallow'

import { Box, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import { DASH } from '../../utils/constants'
import { UsePerformanceStore } from '../../stores/UsePerformanceStore'
import { getUser } from '../../selectors'


function Performance() {
  const user = useSelector(getUser)
  const { list, loading, loadPerformance } = UsePerformanceStore((state) => state, shallow)
  const rows = [
    {
      id: 0,
      title: 'Matching',
    },
    {
      id: 1,
      title: 'Quality bonus booster',
    },
    {
      id: 2,
      title: 'Quantity bonus booster',
    },
  ]

  useEffect(() => {
    loadPerformance(user.id)
  }, [user.id])

  const getColumns = (): GridColDef[] => {
    let columns = [
      {
        field: 'title',
        headerName: '',
        type: 'string',
        minWidth: 220,
        cellClassName: 'MuiDataGrid-cell-stick-left',
      },
    ]
    if (!list) return columns

    list.map(stat => {
      const column: GridColDef = {
        field: stat.date,
        headerName: stat.date,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          const { id } = params

          if (stat.finished && id == 0) {
            return <>
              <span title="Mistakes" className="badge pull-rt">
                {(100 - +stat.resolution_avg).toFixed(2)}%
              </span>
              <b title="Processed / Total">{stat.finished} / {stat.total}</b>
            </>
          }
          if (stat.finished && id == 1) {
            return <>
              <span title="Error Rate" className="badge pull-rt">
                {(stat.reference_error_avg).toFixed(2)}%
              </span>
              {stat.reference_error_avg >= 40 ?'Bad' : stat.reference_error_avg >= 25 ? 'Good' : 'Very Good'}
            </>
          }
          if (stat.finished && id == 2) {
            if (stat.finished <= 200) {
              return '100%'
            }
            if (stat.finished <= 400) {
              return '110%'
            }
            if (stat.finished <= 600) {
              return '115%'
            }
            if (stat.finished <= 800) {
              return '120%'
            }
            return '125%'
          }
          return DASH
        },
        cellClassName: (params) => {
          const { id } = params

          if (stat.resolution_avg && id == 0) {
            if (stat.resolution_avg > 95) return 'bg-success'
            if (stat.resolution_avg > 90) return 'bg-warning'
            return 'bg-danger'
          }
          if (stat.resolution_avg && id == 1) {
            if (stat.reference_error_avg > 40) return 'bg-danger'
            if (stat.reference_error_avg > 20) return 'bg-warning'
            return 'bg-success'
          }
          return ''
        },
      }
      // @ts-ignore
      columns.push(column)
    })

    return columns
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary">
        Recent Performance
      </Typography>
      <DataGrid
        rows={rows}
        columns={getColumns()}
        autoHeight={true}
        loading={loading}
        hideFooter={true}
        // styling
        components={{
          NoRowsOverlay: () => (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              mt: 8,
            }}>
              <Typography color="error">Any performance statistic yet</Typography>
            </Box>
          ),
        }}
      />
    </React.Fragment>
  )
}

export default Performance
