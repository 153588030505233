import Images from './Images'
import Info from './Info'
import { Box, Stack } from '@mui/material'

export interface iEstate {
  id: number
  marked: boolean
  meta: any
  pictures: string[]
  score: number
  type_id: number
}

interface iProps {
  estate?: iEstate

  renderBefore?: any
  renderControls?: any
  renderAfter?: any

  hideImages?: boolean
}

export default function Candidate(props: iProps) {
  const { estate } = props

  return (
    <>
      {props.renderBefore}
      {!props.hideImages && <Images items={estate?.pictures} />}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        spacing={2}
      >
        <Info meta={{
          id: estate?.id,
          type: estate?.meta?.type_name,
          location: estate?.meta?.location,
          price: estate?.meta?.price,
          bedrooms: estate?.meta?.bedrooms,
          bathrooms: estate?.meta?.bathrooms,
          living_area: estate?.meta?.living_area,
          plot_area: estate?.meta?.plot_area,
          total_area: estate?.meta?.total_area,
        }} />
        <Box
          sx={{
            flexDirection: 'column',
            '& > *': {
              m: 1,
            },
          }}
        >
          {props.renderControls}
        </Box>
      </Stack>
      {props.renderAfter}
    </>
  )
}
