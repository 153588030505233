import { call, put } from 'redux-saga/effects'
import AuthActions from '../redux/authRedux'
import MessageActions from '../redux/messageRedux'
import { persistor, store } from '../redux/store'
import API from '../services/api'
import { setToken } from '../services/api-header'


export function* login(action: any) {
  try {
    yield put(AuthActions.updateFetching(true))
    yield put(AuthActions.updateErrors(false))

    // @ts-ignore
    const response = yield call(API.post, 'auth/signin/', action)

    if (response.ok) {
      setToken(response.data.token)
      yield put(AuthActions.updateUser(response.data.user))
    } else {
      yield put(AuthActions.updateErrors(response.data))
      if (response.data.non_field_errors) {
        yield put(MessageActions.openSnackbarWithColor(response.data.non_field_errors, "error"))
      }
      if (response.data.g_recaptcha_token) {
        yield put(AuthActions.updateCaptcha(true))
      }
    }
    yield put(AuthActions.updateFetching(false))
  } catch (e) {
    const result = (e as Error).message;
    console.error("Error on login", result)
    yield call(onError, result)
  }
}

export function* reLogin(action: any) {
  try {
    yield put(AuthActions.updateFetching(true))
    let url = 'users/my/'
    if (action.user_id) {
      url = `users/${action.user_id}/`
      yield put(AuthActions.rememberUser())
    } else {
      yield put(AuthActions.clearRememberUser())
    }

    // @ts-ignore
    const response = yield call(API.get, url)

    if (response.ok) {
      yield put(AuthActions.updateUser(response.data))
    }
    yield put(AuthActions.updateFetching(false))
  } catch (e) {
    console.error("Error on login as", e)
    yield call(onError)
  }
}

export function* loadUserBalance(action: any) {
  try {
    yield put(AuthActions.updateFetching(true))
    let url = 'users/my/'
    if (store.getState().auth.remember_user_id) {
      url = `users/${store.getState().auth.user.id}/`
    }

    // @ts-ignore
    const response = yield call(API.get, url)

    if (response.ok) {
      yield put(AuthActions.updateUser(response.data))
    }
    yield put(AuthActions.updateFetching(false))
  } catch (e) {
    console.error("Error on login as", e)
    yield call(onError)
  }
}

export function* signup(action: any) {
  try {
    yield put(AuthActions.updateFetching(true))
    yield put(AuthActions.updateErrors(false))

    const { history, ...data } = action
    // @ts-ignore
    const response = yield call(API.post, 'auth/signup/', data)

    if (response.ok) {
      history.push('/login')
      yield put(MessageActions.openSnackbarWithColor("Successfully registered, check your email to confirm it!", "success"))
    } else {
      yield put(AuthActions.updateErrors(response.data))
      if (response.data.non_field_errors) {
        yield put(MessageActions.openSnackbarWithColor(response.data.non_field_errors, "error"))
      }
    }
    yield put(AuthActions.updateFetching(false))
  } catch (e) {
    console.error("Error on signup", e)
    yield call(onError)
  }
}

export function* confirmEmail(action: any) {
  try {
    yield put(AuthActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.post, 'auth/confirm-email/', action)

    if (response.ok) {
      yield put(AuthActions.setMessage(response.data))
    } else {
      yield put(AuthActions.updateErrors(response.data))
      if (response.data.non_field_errors) {
        yield put(MessageActions.openSnackbarWithColor(response.data.non_field_errors, "error"))
      }
    }
    yield put(AuthActions.updateFetching(false))
  } catch (e) {
    const result = (e as Error).message;
    console.error("Error on confirm email", result)
    yield call(onError, result)
  }
}

function* onError(message = "Oops.. Something went wrong!") {
  yield put(MessageActions.openSnackbarWithColor(message, "error"))
  yield put(AuthActions.updateFetching(false))
}

export function* requestPasswordReset(action: any) {
  try {
    yield put(AuthActions.updateFetching(true))
    yield put(AuthActions.updateErrors(false))

    // @ts-ignore
    const response = yield call(API.post, 'auth/request-password-reset/', action)

    if (response.ok) {
      yield put(AuthActions.setMessage(response.data))
    } else {
      yield put(AuthActions.updateErrors(response.data))
    }
    yield put(AuthActions.updateFetching(false))
  } catch (e) {
    const result = (e as Error).message;
    console.error("Error on request password reset", result)
    yield call(onError, result)
  }
}

export function* passwordReset(action: any) {
  try {
    yield put(AuthActions.updateFetching(true))
    yield put(AuthActions.updateErrors(false))

    // @ts-ignore
    const response = yield call(API.post, 'auth/password-reset/', action)

    if (response.ok) {
      yield put(AuthActions.setMessage(response.data))
    } else {
      yield put(AuthActions.updateErrors(response.data))
    }
    yield put(AuthActions.updateFetching(false))
  } catch (e) {
    const result = (e as Error).message;
    console.error("Error on request password reset", result)
    yield call(onError, result)
  }
}

export function* logout() {
  try {
    yield put(AuthActions.resetAuthState())
    yield call(persistor.purge)
    setToken('')
    window.location.reload()
  } catch (e) {
    console.error('Error at logging out', e)
  }
}
