import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { shallow } from 'zustand/shallow'

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
} from '@mui/x-data-grid'

import AuthActions from '../../redux/authRedux'
import Page from '../../components/Layout/Page'
import TransactionsActions, { iCreateTransaction, TYPE_PAYOUT } from '../../redux/transactionsRedux'
import UsersActions from '../../redux/usersRedux'
import { currencyFormatter, currencyGetter, parseCurrencyToInt } from '../../utils/formatters'
import { PAGE_SIZES } from '../../utils/constants'
import { UseUserStore } from '../../stores/UseUserStore'

interface iProps {
  user: any

  count: number
  usersList: any
  fetching: boolean

  loadUsersList: (page: number, pageSize: number, sort: any, filter: any) => void
  patchUser: (id: number, data: any) => void
  sendConfirmEmail: (id: number) => void
  reLogin: (user_id: number) => void
  createTransactions: (data: iCreateTransaction) => void
}

interface iPayout {
  for_user: any
  amount: string
  history: {
    [key: number]: string
  }
}

function UsersListPage(props: iProps) {
  const history = useHistory()
  const { user, count, usersList, fetching, loadUsersList } = props
  const [payout, setPayout] = React.useState<iPayout>({
    for_user: false,
    amount: '',
    history: {},
  })
  const userStore = UseUserStore((state) => state, shallow)

  useEffect(() => {
    loadUsersList(userStore.page, userStore.pageSize, userStore.sort, userStore.filter)
  }, [loadUsersList, userStore.page, userStore.pageSize, userStore.sort, userStore.filter])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      width: 90,
      filterOperators: getGridNumericOperators().filter(
        (operator) => ['=', '>', '<'].includes(operator.value),
      ),
    },
    {
      field: 'first_name',
      headerName: 'First name',
      type: 'string',
      width: 150,
      editable: user.permissions.allow_manage,
      filterOperators: getGridStringOperators().filter(
        (operator) => ['equals', 'contains'].includes(operator.value),
      )
    },
    {
      field: 'last_name',
      headerName: 'Last name',
      type: 'string',
      width: 150,
      editable: user.permissions.allow_manage,
      filterOperators: getGridStringOperators().filter(
        (operator) => ['equals', 'contains'].includes(operator.value),
      )
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      width: 200,
      filterOperators: getGridStringOperators().filter(
        (operator) => ['equals', 'contains'].includes(operator.value),
      )
    },
    {
      field: 'is_email_confirm',
      headerName: 'Email confirmation',
      type: 'boolean',
      width: 150,
      editable: true,
    },
    {
      field: 'email_confirm_sent_at',
      headerName: 'Email confirmation sent',
      type: 'date',
      width: 150,
      valueFormatter: (params) => {
        if (params.value) {
          let date = new Date(Date.parse(params.value as string))
          return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
        }
      },
    },
    {
      field: 'email_confirm_token',
      headerName: 'Email confirmation token',
      type: 'string',
      width: 150,
    },
    {
      field: 'is_active',
      headerName: 'Active',
      type: 'boolean',
      width: 150,
      editable: true,
    },
    {
      field: 'is_ban',
      headerName: 'Banned',
      type: 'boolean',
      width: 150,
      editable: true,
    },
    {
      field: 'is_annotator',
      headerName: 'Annotator',
      type: 'boolean',
      width: 150,
      editable: true,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      type: 'number',
      width: 150,
      filterOperators: getGridNumericOperators().filter(
        (operator) => ['=', '>', '<'].includes(operator.value),
      ),
      editable: false,
      valueGetter: (params) => currencyGetter(params.value),
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      field: 'match_price',
      headerName: 'Match price',
      type: 'number',
      width: 150,
      filterOperators: getGridNumericOperators().filter(
        (operator) => ['=', '>', '<'].includes(operator.value),
      ),
      editable: user.permissions.allow_payout,
      valueGetter: (params) => currencyGetter(params.value),
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      field: 'dispute_price',
      headerName: 'Dispute price',
      type: 'number',
      width: 150,
      filterOperators: getGridNumericOperators().filter(
        (operator) => ['=', '>', '<'].includes(operator.value),
      ),
      editable: user.permissions.allow_payout,
      valueGetter: (params) => currencyGetter(params.value),
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      field: 'permissions.allow_matching',
      headerName: 'Access to vote',
      type: 'boolean',
      width: 150,
      editable: true,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.permissions.allow_matching
      }
    },
    {
      field: 'permissions.allow_dispute',
      headerName: 'Access to dispute',
      type: 'boolean',
      width: 150,
      editable: true,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.permissions.allow_dispute
      }
    },
    {
      field: 'permissions.allow_users',
      headerName: 'Access to users',
      type: 'boolean',
      width: 150,
      editable: true,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.permissions.allow_users
      }
    },
    {
      field: 'permissions.allow_payout',
      headerName: 'Access to payout',
      type: 'boolean',
      width: 150,
      editable: true,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.permissions.allow_payout
      }
    },
    {
      field: 'permissions.allow_manage',
      headerName: 'Access as manager',
      type: 'boolean',
      width: 150,
      editable: true,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.permissions.allow_manage
      }
    },
    {
      field: 'permissions.allow_blacklist_images',
      headerName: 'Access to black list images',
      type: 'boolean',
      width: 150,
      editable: true,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.permissions.allow_blacklist_images
      }
    },
    {
      field: 'date_joined',
      headerName: 'Created At',
      type: 'date',
      width: 150,
      filterOperators: getGridDateOperators().filter(
        (operator) => ['is', 'after', 'before'].includes(operator.value),
      ),
      valueFormatter: (params) => {
        if (params.value) {
          let date = new Date(Date.parse(params.value as string))
          return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
        }
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 265,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        let buttons = [
          <Button
            key="view"
            onClick={() => {
              props.reLogin(params.row.id)
              history.push('/')
            }}
          >
            View
          </Button>
        ]

        if (user.permissions.allow_payout) {
          buttons.push(
            <Button
              key="payout"
              color="success"
              onClick={() => setPayout({
                ...payout,
                for_user: params.row,
                amount: payout.history[params.row.id] || '',
              })
            }>
              Create payout
            </Button>
          )
        }

        if (!params.row.is_email_confirm) {
          buttons = [
            <Button
              key="confirmEmail"
              color="warning"
              onClick={async () => {
                if (window.confirm(`Last email was sent at ${params.row.confirm_email_sent_at}.`)) {
                  props.sendConfirmEmail(params.row.id)
                }
              }}
            >
              Send confirm email
            </Button>
          ]
        }

        return <ButtonGroup variant="outlined">{buttons}</ButtonGroup>
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      type: 'string',
      width: 400,
      editable: true,
      filterable: false,
    },
  ]

  const handleCellEditCommit = async (params: GridCellEditCommitParams) => {
    let data: any = {}
    if (params.field.includes('.')) {
      const [field, subField] = params.field.split('.')
      data[field] = {}
      data[field][subField] = params.value
    } else {
      data[params.field] = params.field.includes('_price') ? parseCurrencyToInt(params.value) : params.value
    }
    await props.patchUser(+params.id, data)
    loadUsersList(userStore.page, userStore.pageSize, userStore.sort, userStore.filter)
  }

  const getAmount = (): number => Math.trunc(parseFloat(payout.amount) * 100)
  const isPayoutValid = (): boolean => getPayoutErrorMessage() ? false : true
  const getPayoutErrorMessage = (): string => {
    if (!getAmount()) return 'Required'
    if (getAmount() > payout.for_user.balance) return 'Try payout more than at balance'
    return ''
  }

  const handleCreatePayout = () => {
    if (!isPayoutValid()) {
      if (!window.confirm(getPayoutErrorMessage())) {
        return;
      }
    }

    const data: iCreateTransaction = {
      type: TYPE_PAYOUT,
      user_id: payout.for_user.id,
      compensation: -getAmount(),
    }
    props.createTransactions(data)
    loadUsersList(userStore.page, userStore.pageSize, userStore.sort, userStore.filter)
    handleClosePayoutModal('')
  }

  const handleClosePayoutModal = (amountForHistory: string) => {
    setPayout({
      ...payout,
      for_user: false,
      amount: '',
      history: {...payout.history, [payout.for_user.id]: amountForHistory}
    })
  }

  const renderToolbar = () => (
    <GridToolbar
      csvOptions={{
        fileName: 'users',
        delimiter: ';',
        utf8WithBom: true,
      }}
    />
  )

  return (
    <Page
      className="usersListPage"
      title="Users list"
    >
      <DataGrid
        rows={usersList}
        columns={columns}
        autoHeight={true}
        components={{
          Toolbar: renderToolbar,
        }}
        // columns
        columnVisibilityModel={userStore.shownColumns}
        onColumnVisibilityModelChange={userStore.setShownColumns}
        // pagination
        rowsPerPageOptions={PAGE_SIZES}
        rowCount={count}
        page={userStore.page}
        pageSize={userStore.pageSize}
        paginationMode="server"
        onPageChange={userStore.setPage}
        onPageSizeChange={userStore.setPageSize}
        // sorting
        sortingMode="server"
        sortModel={userStore.sort}
        onSortModelChange={userStore.setSort}
        // filtering
        filterMode="server"
        filterModel={userStore.filter}
        onFilterModelChange={userStore.setFilter}
        // editing
        onCellEditCommit={handleCellEditCommit}
        loading={fetching}
        // lock for staff
        isCellEditable={(params) => !params.row.is_staff}
      />
      <Dialog open={payout.for_user} onClose={() => handleClosePayoutModal(payout.amount)}>
        <DialogTitle>Payout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography color="main" variant="subtitle1" sx={{ fontStyle: 'bold', lineHeight: 1 }}>
              {payout.for_user.last_name} {payout.for_user.first_name}
            </Typography>
            <Typography color="secondary" variant="subtitle2" sx={{ lineHeight: 1 }}>
              {payout.for_user.email}
            </Typography>

            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              sx={{ my: 3, justifyContent: "space-around" }}
            >
              <Typography># {payout.for_user.id}</Typography>
              <Typography>{currencyFormatter(payout.for_user.balance, true)}</Typography>
            </Stack>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Amount"
            fullWidth
            error={!isPayoutValid()}
            helperText={getPayoutErrorMessage()}
            variant="standard"
            value={payout.amount}
            onChange={({ target: { value } }) => {
              if (/^\d{0,4}(\.\d{0,2})?$/.test(value)) setPayout({ ...payout, amount: value })
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClosePayoutModal(payout.amount)}>Cancel</Button>
          <Button
            disabled={!isPayoutValid()}
            title={getPayoutErrorMessage()}
            onClick={handleCreatePayout}
          >Payout</Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,

    count: state.users.count,
    usersList: state.users.usersList,
    fetching: state.users.fetching,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadUsersList: (page: number, pageSize: number, sort: any, filter: any) => dispatch(UsersActions.loadUsersList(page, pageSize, sort, filter)),
    patchUser: (id: number, data: any) => dispatch(UsersActions.patchUser(id, data)),
    sendConfirmEmail: (id: number) => dispatch(UsersActions.sendConfirmEmail(id)),
    reLogin: (user_id: number) => dispatch(AuthActions.reLogin(user_id)),
    createTransactions: (data: iCreateTransaction) => dispatch(TransactionsActions.createTransactions(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersListPage);
