import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces'
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../utils/constants'

interface UserState {
  shownColumns: GridColumnVisibilityModel
  setShownColumns: (columnModel: GridColumnVisibilityModel) => void

  page: number
  setPage: (page: number) => void

  pageSize: number
  setPageSize: (pageSize: number) => void

  sort: GridSortModel
  setSort: (sort: GridSortModel) => void

  filter: GridFilterModel | undefined
  setFilter: (filter: GridFilterModel) => void
  clearFilter: () => void
}

export const UseUserStore = create<UserState>()(
  devtools(
    persist(
      (set, get) => ({
        shownColumns: {
          id: true,
          first_name: true,
          last_name: true,
          email: true,
          is_email_confirm: false,
          email_confirm_sent_at: false,
          email_confirm_token: false,
          is_active: true,
          is_ban: true,
          is_annotator: true,
          is_staff: false,
          balance: false,
          match_price: false,
          dispute_price: false,
          'permissions.allow_matching': true,
          'permissions.allow_dispute': false,
          'permissions.allow_users': false,
          'permissions.allow_payout': false,
          'permissions.allow_manage': false,
          date_joined: false,
          action: true,
          comment: true,
        },
        setShownColumns: (shownColumns) => set({ shownColumns }),

        page: DEFAULT_PAGE,
        setPage: (page) => set({ page }),

        pageSize: DEFAULT_PAGE_SIZE,
        setPageSize: (pageSize) => set({ pageSize, page: DEFAULT_PAGE }),

        sort: [],
        setSort: (sort) => set({ sort }),

        filter: undefined,
        setFilter: (filter) => set({ filter }),
        clearFilter: () => set({ filter: undefined }),
      }),
      {
        name: 'user-store',
      }
    )
  )
)
