const STORAGE_KEY = 'accessToken'

export default function apiHeader(): HeadersInit {
  let headers = new Headers()
  headers.append('Content-Type', 'application/json')

  // @ts-ignore
  const token = localStorage.getItem(STORAGE_KEY)
  if (token) {
    headers.append('Authorization', 'Token ' + token)
  }

  return headers
}

export function setToken(token: string) {
  localStorage.setItem(STORAGE_KEY, token)
}