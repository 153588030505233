import { createSelector } from 'reselect';

export const getVoting = store => store.voting

export const getVote = createSelector(
  getVoting,
  ({ vote }) => vote,
)

export const getParentCheckId = createSelector(
  getVote,
  ({ parent_check, parent_check_id }) => parent_check || parent_check_id,
)

export const getIsReferenceVote = createSelector(
  getVote,
  ({ is_reference_vote }) => is_reference_vote || false,
)

const exportedObject = {
  getVoting,
  getIsReferenceVote,
  getParentCheckId,
}

export default exportedObject
