import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loadBucketsList: [],
  loadListingStatistic: ['filter'],
  loadLayerStatistic: ['filter'],
  patchBuckets: ['data'],

  updateCount: ['count'],
  updateList: ['list'],
  updateItem: ['item'],
  updateStatistic: ['statistic'],
  updateListingStatistic: ['listingStatistic'],
  updateFetching: ['fetching'],
})

export const BucketsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export interface iBucket {
  id: number
  country: string
  location: string
  priority?: number
  new_priority?: number
  deduplication_speed?: number
  adding_speed?: number
  not_processed?: number
}

export interface iLayer {
  date: string
  layer_adm_find_candidates_finish?: number
  layer_adm_find_candidates_start?: number
  layer_adm_listing_not_ready_for_adm?: number
  layer_adm_process_results_finish?: number
  layer_adm_process_results_start?: number
  layer_annotators_find_candidates_finish?: number
  layer_annotators_find_candidates_start?: number
  layer_annotators_process_results_finish?: number
  layer_annotators_process_results_start?: number
  layer_bp_find_candidates_finish?: number
  layer_bp_find_candidates_start?: number
  layer_bp_process_results_finish?: number
  layer_bp_process_results_start?: number
  send_to_orchestrator_success?: number
  layer_final_process_results_finish?: number
  layer_final_process_results_start?: number
  layer_final_process_results_to_large_graph?: number
  // skiped
  // adm_service_got_error?: number
  // bp_service_got_error?: number
  // layer_manual_add_manuak_connections?: number
  // layer_manual_add_manual_connections?: number
}

export interface iListingStatistic extends iLayer{
  total: number
  shown: number
  residential: number
  non_residential: number
}

type BucketsState = {
  count: number,
  list: [iBucket],
  item: iBucket|null,
  statistic: [iLayer],
  fetching: boolean,
  listingStatistic: [iListingStatistic],
}

export const INITIAL_STATE = Immutable({
  count: 0,
  list: [],
  item: null,
  statistic: [],
  fetching: false,
  listingStatistic: [],
})

/* ------------- Reducers ------------- */

export const updateCount = (state: any, { count }: any) =>
  state.merge({ count })

export const updateList = (state: any, { list }: any) =>
  state.merge({ list })

export const updateItem = (state: any, { item }: any) =>
  state.merge({ item })

export const updateStatistic = (state: any, { statistic }: any) =>
  state.merge({ statistic })

export const updateFetching = (state: any, { fetching }: any) =>
  state.merge({ fetching })

export const updateListingStatistic = (state: any, {listingStatistic}: any) =>
  state.merge({ listingStatistic })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_COUNT]: updateCount,
  [Types.UPDATE_LIST]: updateList,
  [Types.UPDATE_ITEM]: updateItem,
  [Types.UPDATE_STATISTIC]: updateStatistic,
  [Types.UPDATE_FETCHING]: updateFetching,
  [Types.UPDATE_LISTING_STATISTIC]: updateListingStatistic,
})
