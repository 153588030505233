import React, { useEffect } from 'react'
import Page from '../../components/Layout/Page'
import { connect } from 'react-redux'

import { Autocomplete, Box, Paper, Stack, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import DeduplicationStatByListing from '../../components/widgets/DeduplicationStatByListing'
import DeduplicationStatTotal from '../../components/widgets/DeduplicationStatTotal'
import BucketsActions, { iBucket } from '../../redux/bucketsRedux'
import { MIN_DEFAULT_RANGE } from '../../utils/constants'
import { dateToISO } from '../../utils/formatters'
import { validateDateRange } from '../../utils/functions'


interface iProps {
  selectedBucket: iBucket|null
  buckets: [iBucket]

  loadBuckets: () => void
  selectBucket: (item: iBucket|null) => void
  loadStatistic: (filter: any) => void
  loadListingStatistic: (filter: any) => void
}

const END_DATE = new Date()
const START_DATE = new Date()
START_DATE.setDate(END_DATE.getDate() - MIN_DEFAULT_RANGE)

function DeduplicationPage(props: iProps) {
  const { selectedBucket, buckets } = props
  const [startDate, setStartDate] = React.useState<Date>(START_DATE)
  const [endDate, setEndDate] = React.useState<Date>(END_DATE)

  useEffect(() => {
    props.loadBuckets()
  }, [])

  useEffect(() => {
    if (getError()) return

    let filter = {
      items: [
        {
          columnField: 'created_at',
          operatorValue: '>',
          value: dateToISO(startDate),
        },
        {
          columnField: 'created_at',
          operatorValue: '<',
          value: dateToISO(endDate),
        },
      ]
    }

    if (selectedBucket) {
      filter.items.push({
        columnField: 'bucket_id',
        operatorValue: '=',
        value: selectedBucket.id.toString(),
      })
      props.loadListingStatistic(filter)
    }

    props.loadStatistic(filter)
  }, [startDate, endDate, selectedBucket])

  const getError = () => !!validateDateRange(startDate, endDate)

  return (
    <Page
      className="deduplicationPage"
      title="Deduplication statistic"
    >
      <Paper elevation={3} style={{ marginBottom: 24, padding: 16, justifyContent: "center" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          spacing={2}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" flexDirection="row">
              <DatePicker
                inputFormat="E dd MMM yyyy"
                label="Start date"
                value={startDate}
                onChange={(value: any) => setStartDate(value)}
                renderInput={(params: any) => <TextField
                  {...params}
                  error={getError()}
                  helperText={validateDateRange(startDate, endDate)}
                />}
              />
              <Box display="flex" alignItems="center" sx={{ mx: 2 }}> to </Box>
              <DatePicker
                inputFormat="E dd MMM yyyy"
                label="End date"
                value={endDate}
                onChange={(value: any) => setEndDate(value)}
                renderInput={(params: any) => <TextField {...params} error={getError()} />}
              />
            </Box>
          </LocalizationProvider>

          <Autocomplete
            sx={{display: 'flex'}}
            fullWidth
            groupBy={(option: iBucket) => option.country}
            getOptionLabel={(option: iBucket) => typeof option === 'string' ? option : option.location}
            options={buckets}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Find bucket by country or location"
                fullWidth
              />
            )}
            value={selectedBucket}
            onChange={(event, newValue) => props.selectBucket(newValue)}
          />
        </Stack>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', p: 4 }}>
          <Typography component="h2" variant="h6" color="primary" align="center">Total in day:</Typography>
          <DeduplicationStatTotal />
        </Box>

        {selectedBucket &&(
          <Box sx={{ borderBottom: 1, borderColor: 'divider', p: 4 }}>
            <Typography component="h2" variant="h6" color="primary" align="center">
              Created in {selectedBucket.location} bucket:
            </Typography>
            <DeduplicationStatByListing />
          </Box>
        )}
      </Paper>
    </Page>
  )
}

const mapStateToProps = (state: any) => {
  return {
    selectedBucket: state.buckets.item,
    buckets: state.buckets.list,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectBucket: (item: iBucket|null) => dispatch(BucketsActions.updateItem(item)),
    loadBuckets: () => dispatch(BucketsActions.loadBucketsList()),
    loadStatistic: (filter: any) => dispatch(BucketsActions.loadLayerStatistic(filter)),
    loadListingStatistic: (filter: any) => dispatch(BucketsActions.loadListingStatistic(filter)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeduplicationPage)
