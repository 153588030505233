import React from 'react'
import { Button, ButtonGroup } from '@mui/material'
import VotingActions from '../../redux/votingRedux'
import { connect } from 'react-redux'

interface iProps {
  fetching: boolean
  marked_duplicates: number[] | null

  finish: () => void
  goBack: () => void
  resetCandidateDuplicates: () => void
}

function SourceControls(props: iProps) {
  const { fetching, marked_duplicates } = props

  const isMarked = () => !(marked_duplicates == null || marked_duplicates.length == 0)

  return (
    <ButtonGroup variant="contained" size="small" color="inherit" disabled={fetching}>
      <Button
        color="warning"
        onClick={() => {
          props.goBack()
        }}
      >Undo previous</Button>
      <Button
        color="error"
        onClick={() => {
          props.resetCandidateDuplicates()
          props.finish()
        }}
        disabled={isMarked()}
        title={isMarked() ? 'Unmark all candidates' : ''}
      >No match</Button>
      <Button
        disabled={!isMarked()}
        title={isMarked() ? '' : 'Mark at least one of the candidates'}
        color="success"
        onClick={() => {
          props.finish()
        }}
      >Save matching</Button>
    </ButtonGroup>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetching: state.voting.fetching,
    marked_duplicates: state.voting.vote.marked_duplicates,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    finish: () => dispatch(VotingActions.finish()),
    goBack: () => dispatch(VotingActions.goBack()),
    resetCandidateDuplicates: () => dispatch(VotingActions.resetCandidateDuplicates()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceControls)
