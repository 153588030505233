import React, { useMemo } from 'react'
import { connect } from 'react-redux'

import ReactApexChart from 'react-apexcharts'
import { DataGrid } from '@mui/x-data-grid'
import { Typography } from '@mui/material'

import { iListingStatistic } from '../../redux/bucketsRedux'
import { objectMap } from '../../utils/functions'


interface iProps {
  statistic: [iListingStatistic]
  fetching: boolean
}

function DeduplicationStatByListing(props: iProps) {
  const { statistic, fetching } = props

  const chart = useMemo(() => {
    let categories: string[] = []
    let created_data: number[] = []
    let shown_data: number[] = []
    let residential_data: number[] = []
    let non_residential_data: number[] = []

    objectMap(statistic, (row: iListingStatistic) => {
      categories.push(row.date)
      created_data.push(row.total)
      shown_data.push(row.shown)
      residential_data.push(row.residential)
      non_residential_data.push(row.non_residential)
    })

    return {
      series: [
        {name: 'Total Created', data: created_data},
        {name: 'Shown on prod', data: shown_data},
        {name: 'Residential', data: residential_data},
        {name: 'Non Residential', data: non_residential_data},
      ],
      options: {
        chart: {
          toolbar: {
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            }
          }
        },
        xaxis: {
          categories,
        }
      },
    }
  }, [statistic])

  const chart2 = useMemo(() => {
    let categories: string[] = []
    let adm_data: number[] = []
    let bp_data: number[] = []
    let annotators_data: number[] = []
    let total_data: number[] = []

    objectMap(statistic, (row: iListingStatistic) => {
      categories.push(row.date)
      const adm = +(row.layer_adm_process_results_finish || 0) / +(row.total) * 100
      adm_data.push(Math.round(adm * 100) / 100)
      const bp = +(row.layer_bp_process_results_finish || 0) / +(row.total) * 100
      bp_data.push(Math.round(bp * 100) / 100)
      const annotators = +(row.layer_annotators_process_results_finish || 0) / +(row.total) * 100
      annotators_data.push(Math.round(annotators * 100) / 100)
      const total = +(row.layer_final_process_results_finish || 0) / +(row.total) * 100
      total_data.push(Math.round(total * 100) / 100)
    })

    return {
      series: [
        {name: 'Adm %', data: adm_data},
        {name: 'BP %', data: bp_data},
        {name: 'Annotators %', data: annotators_data},
        {name: 'Total %', data: total_data},
      ],
      options: {
        chart: {
          toolbar: {
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            }
          }
        },
        xaxis: {
          categories,
        }
      },
    }
  }, [statistic])

  const chart3 = useMemo(() => {
    let categories: string[] = []
    let bp_data: number[] = []
    let pipeline_data: number[] = []

    objectMap(statistic, (row: iListingStatistic) => {
      categories.push(row.date)
      const bp = +(row.layer_bp_find_candidates_finish || 0) / +(row.total) * 100
      bp_data.push(Math.round(bp * 100) / 100)
      const pipeline = (+(row.layer_adm_process_results_finish || 0) + +(row.layer_annotators_process_results_finish || 0)) / +(row.total) * 100
      pipeline_data.push(Math.round(pipeline * 100) / 100)
    })

    return {
      series: [
        {name: '% Finish BP', data: bp_data},
        {name: '% Finish Pipeline', data: pipeline_data},
      ],
      options: {
        chart: {
          toolbar: {
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            }
          }
        },
        xaxis: {
          categories,
        }
      },
    }
  }, [statistic])

  return (
    <>
      <ReactApexChart options={chart.options} series={chart.series} type="line" height={200} />
      <ReactApexChart options={chart2.options} series={chart2.series} type="line" height={200} />
      <ReactApexChart options={chart3.options} series={chart3.series} type="line" height={200} />
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'asc' }],
          },
        }}
        getRowId={(row) => row.date}
        rows={statistic}
        columns={[
          {
            field: 'date',
            headerName: 'Date',
            type: 'date',
            width: 150,
            filterable: false,
          },
          {
            field: 'total',
            headerName: 'Total Created',
            type: 'number',
            width: 150,
          },
          {
            field: 'shown',
            headerName: 'Shown on prod',
            type: 'number',
            width: 150,
          },
          {
            field: 'residential',
            headerName: 'Residential',
            type: 'number',
            width: 150,
          },
          {
            field: 'non_residential',
            headerName: 'Non Residential',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_adm_find_candidates_start',
            headerName: 'ADM Start',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_adm_process_results_finish',
            headerName: 'ADM Finished',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_bp_find_candidates_start',
            headerName: 'BP Start',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_bp_process_results_finish',
            headerName: 'BP Finished',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_annotators_find_candidates_start',
            headerName: 'Annotators Start',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_annotators_process_results_finish',
            headerName: 'Annotators Finished',
            type: 'number',
            width: 150,
          },
          {
            field: 'send_to_orchestrator_success',
            headerName: 'Total Start',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_final_process_results_finish',
            headerName: 'Total Finished',
            type: 'number',
            width: 150,
          },
        ]}
        sortingMode="client"
        filterMode="client"
        loading={fetching}
        autoHeight={true}
        pageSize={25}
      />
      <Typography variant="body2" color="text.secondary">
        <i>Select all listings which have been created in data range.</i>
        <br />
        <i>Add events statistic by those listings in data range.</i>
        <br />
        <i>% Finished BP</i> - Compared to what is waiting on that day for BP E/A*100
        <br />
        <i>% Finished the entire deduplication pipeline</i> - Compared to what is waiting on that day G/A*100
        <br />
        <i>E</i> - Count total number listings that finished bulletproof and found candidates
        <br />
        <i>G</i> - Count total number of listings that finished advanced model and/or the annotators
        <br />
        <i>A</i> - Total count of created listings
      </Typography>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    statistic: state.buckets.listingStatistic,
    fetching: state.buckets.fetching,
  }
}

export default connect(mapStateToProps)(DeduplicationStatByListing)
