import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import LayoutRoute from './LayoutRoute'
import MessageActions from '../../redux/messageRedux'

function BlackListRoute(props: any) {
  if (props.user?.is_ban) {
    props.alert('Your account has been banned for bad quality.')
    return <Redirect to={{ pathname: '/'}} />
  }
  if (props.user?.permissions?.allow_blacklist_images) {
    return <LayoutRoute {...props} />
  } else {
    props.alert('Has no permissions to black list images, ask manager')
    return <Redirect to={{ pathname: '/'}} />
  }
}

const mapStateToProps = ( state : any ) => {
  return {
    user: state.auth.user,
  }
}

const mapDispatchToProps = ( dispatch : any ) => {
  return {
    alert: (message: string) => dispatch(MessageActions.openSnackbarWithColor(message, 'error')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlackListRoute)
