import React, { useEffect, useMemo } from 'react'
import Page from '../../components/Layout/Page'
import { connect } from 'react-redux'
import { Button, Typography } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridCellEditCommitParams,
  GridSelectionModel,
} from '@mui/x-data-grid'
import BucketsActions from '../../redux/bucketsRedux'

interface iProps {
  count: number,
  list: any,
  fetching: boolean,

  loadBucketsList: () => void,
  updateList: (list: any) => void,
  patchBuckets: (data: any) => void,
}

const PRIORITY_MAP = {
  0: 'Off',
  1: 'Low',
  4: 'Normal',
  6: 'High',
  10: 'Highest',
}

function BucketsListPage(props: iProps) {
  const { count, list, fetching } = props
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID #',
      type: 'number',
      width: 100,
      hide: true,
    },
    {
      field: 'country',
      headerName: 'Country',
      type: 'string',
      width: 150,
    },
    {
      field: 'location',
      headerName: 'Location',
      type: 'string',
      width: 300,
    },
    {
      field: 'weight',
      headerName: 'Priority',
      type: 'singleSelect',
      width: 200,
      editable: true,
      valueOptions: Object.keys(PRIORITY_MAP),
      valueGetter: params => params.row.new_priority || params.row.priority,
      valueFormatter: params => {
        // @ts-ignore
        return PRIORITY_MAP[params.value]
      },
    },
    {
      field: 'performance_allocation',
      headerName: 'Performance allocation',
      type: 'number',
      width: 150,
      valueGetter: (params) => {
        const priority = params.row.new_priority || params.row.priority
        if (priority) {
          return (priority / totalPriority * 100).toFixed(2)
        }
        return 0
      },
      valueFormatter: params => `${params.value} %`,
      filterable: false,
    },
    {
      field: 'proces_speed',
      headerName: 'Deduplication Speed',
      type: 'number',
      width: 150,
      valueFormatter: params => roundFormatterSpeed(params.value),
      filterable: false,
    },
    {
      field: 'adding_speed',
      headerName: 'New listing adding speed',
      type: 'number',
      width: 150,
      valueFormatter: params => roundFormatterSpeed(params.value),
      filterable: false,
    },
    {
      field: 'not_processed',
      headerName: 'Not processed',
      type: 'number',
      width: 150,
    },
    {
      field: 'estimated_time',
      headerName: 'Estimated time',
      width: 150,
      valueGetter: (params) => {
        if (!(params.row.new_priority || params.row.priority) || !params.row.proces_speed) return 0
        const iteration = params.row.not_processed / params.row.proces_speed
        const nextIteration = (iteration * params.row.adding_speed) / params.row.proces_speed
        return iteration + nextIteration
      },
      valueFormatter: params => {
        if (!params.value) return '∞'

        // @ts-ignore
        const date = new Date(params.value * 1000)
        let result = ''

        if (date.getDay()) result += date.getDay() + ' days '
        if (date.getHours()) result += date.getHours() + ' hours '
        if (date.getMinutes()) result += date.getMinutes() + ' mins '
        if (result == '') {
          result = 'less 1 min'
        }
        return result
      },
      filterable: false,
    },
  ]

  useEffect(() => {
    props.loadBucketsList()
  }, [])

  const handleCellEditCommit = (params: GridCellEditCommitParams) => {
    const priority = params.value || 0
    props.updateList(list.map((item: any) =>
      (selectionModel.includes(item.id) || item.id == params.id) ? {...item, 'new_priority': priority} : item)
    )
    setSelectionModel([])
  }

  const changedRows = useMemo(() => {
    if (list.length == 0) return []
    return list.filter((item: any) => item.new_priority && item.new_priority !== item.priority)
  }, [list]);

  const roundFormatterSpeed = (value: any) => {
    if (typeof value == 'number') {
      return value.toFixed(2) + ' listings/sec'
    }
  }

  const totalPriority = useMemo(() => {
    if (list.length == 0) return 0
    let sum = 0
    list.map((item: any) => {
      sum += +(item.new_priority || item.priority)
    })
    return sum
  }, [list])

  const handleSave = () => {
    const changes: any = {}
    changedRows.map((item: any) => changes[item.id] = item.new_priority)
    props.patchBuckets(changes)
    props.loadBucketsList()
  }

  return (
    <Page
      className="bucketsListPage"
      title="Buckets list"
    >
      <div style={{ display: 'flex', height: 'calc(100vh - 210px)' }}>
        <DataGrid
          disableSelectionOnClick={true}
          rows={list}
          columns={columns}
          rowCount={count}
          sortingMode="client"
          filterMode="client"
          // editing
          checkboxSelection={true}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel)
          }}
          selectionModel={selectionModel}
          onCellEditCommit={handleCellEditCommit}
          loading={fetching}
          components={{
            Footer: () => {
              return <Typography align="right" m={2}>
                <Button
                  color="success"
                  variant="contained"
                  onClick={handleSave}
                  disabled={fetching || changedRows.length == 0}
                >
                  {changedRows.length ? 'Save' : 'Nothing to change'}
                </Button>
              </Typography>
            },
          }}
          // styled
          sx={{
            '& .edited': {
              bgcolor: (theme) => theme.palette.primary.light,
              '&:hover': {
                bgcolor: (theme) => theme.palette.primary.main,
              },
            },
          }}
          getRowClassName={(params) =>
            params.row.new_priority && params.row.new_priority != params.row.priority ? 'edited' : ''
          }
        />
      </div>
    </Page>
  )
}

const mapStateToProps = (state: any) => {
  return {
    count: state.buckets.count,
    list: state.buckets.list,
    fetching: state.buckets.fetching,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadBucketsList: () => dispatch(BucketsActions.loadBucketsList()),
    updateList: (list: any) => dispatch(BucketsActions.updateList(list)),
    patchBuckets: (data: any) => dispatch(BucketsActions.patchBuckets(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketsListPage)
