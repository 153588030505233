import { createSelector } from 'reselect';

export const getUser = store => store.auth.user

export const getPermissions = createSelector(
  getUser,
  ({ permissions }) => permissions,
)

const exportedObject = {
  getUser,
  getPermissions,
}

export default exportedObject
