import { all, call, put } from 'redux-saga/effects'
import BucketsActions from '../redux/bucketsRedux'
import MessageActions from '../redux/messageRedux'
import API from '../services/api'
import { buildUrls } from '../utils/functions'

export function* loadBucketsList(action: any) {
  try {
    yield put(BucketsActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.get, `ch/buckets/`)

    if (response.ok) {
      yield put(BucketsActions.updateCount(response.data.count))
      yield put(BucketsActions.updateList(response.data.results))
    } else {
      yield put(BucketsActions.updateCount(0))
      yield put(BucketsActions.updateList([]))
      yield put(MessageActions.openSnackbarWithColor("Not loaded!", "error"))
    }

    yield put(BucketsActions.updateFetching(false))
  } catch (e) {
    console.error("Error on load buckets list", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

export function* loadListingStatistic(action: any) {
  try {
    yield put(BucketsActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.get, buildUrls('ch/listing-statistic/', action))

    if (response.ok) {
      yield put(BucketsActions.updateListingStatistic(response.data.results))
    } else {
      yield put(BucketsActions.updateListingStatistic([]))
      yield put(MessageActions.openSnackbarWithColor("Not loaded!", "error"))
    }

    yield put(BucketsActions.updateFetching(false))
  } catch (e) {
    console.error("Error on load bucket's statistic", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

export function* patchBuckets(action: any) {
  try {
    yield put(BucketsActions.updateFetching(true))

    yield all(Object.keys(action.data).map(bucket_id => {
      const weight = action.data[bucket_id]
      return call(API.post, 'ds/bucket-priority/', { bucket_id, weight })
    }))

    yield put(MessageActions.openSnackbarWithColor("Successfully saved!", "success"))
  } catch (e) {
    console.error("Error on patch bucket", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

export function* loadLayerStatistic(action: any) {
  try {
    yield put(BucketsActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.get, buildUrls(`ch/layer-statistic/`, action))

    if (response.ok) {
      yield put(BucketsActions.updateStatistic(response.data.results))
    } else {
      yield put(BucketsActions.updateStatistic([]))
      yield put(MessageActions.openSnackbarWithColor("Statistic not enable, try later!", "error"))
    }

    yield put(BucketsActions.updateFetching(false))
  } catch (e) {
    console.error("Error on load layer statistic", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

function* onError(message = "Oops.. Something went wrong!") {
  yield put(MessageActions.openSnackbarWithColor(message, "error"))
  yield put(BucketsActions.updateFetching(false))
}
