import { call, put } from 'redux-saga/effects'

import VotingActions from '../redux/votingRedux'
import MessageActions from '../redux/messageRedux'
import API from '../services/api'
import { store } from '../redux/store'


const CANDIDATES_LIMIT = 30; // @see settings.CANDIDATES_LIMIT

function* loadTask(data: any) {
  // firstly reset data
  yield put(VotingActions.updateVote(false))
  yield put(VotingActions.updateTarget(false))
  yield put(VotingActions.updateCandidates(false))

  yield put(VotingActions.updateVote(data.vote))
  yield put(VotingActions.updateTarget(data.target))
  if (!data.vote.is_reference_vote && data.candidates.length > CANDIDATES_LIMIT) {
    const warning = `Evaluating request #${data.vote.parent_check} has ${data.candidates.length} candidates, slice to ${CANDIDATES_LIMIT}`
    console.error(warning)
    yield put(MessageActions.openSnackbarWithColor(warning, "warning"))
    yield put(VotingActions.updateCandidates(data.candidates.slice(0, CANDIDATES_LIMIT)))
  } else {
    yield put(VotingActions.updateCandidates(data.candidates))
  }
  window.scrollTo(0, 0)
}

export function* goNext(action: any) {
  try {
    yield put(VotingActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.get, 'voting/select-task/')

    if (response.ok) {
      yield call(loadTask, response.data)
    } else {
      throw new Error(response.data.detail ?? response.data)
    }
    yield put(VotingActions.updateFetching(false))
  } catch (e) {
    console.error("Error on get task", e)
    const result = (e as Error).message;
    if (result === 'NetworkError when attempting to fetch resource.') yield call(onError, 'System overloaded, pls reload page.')
    else yield call(onError, result)
  }
}

export function* goBack(action: any) {
  try {
    yield put(VotingActions.updateFetching(true))
    const vote = store.getState().voting.vote

    // @ts-ignore
    const response = yield call(API.get, `voting/previous-task/${vote.id}/`)
    yield put(VotingActions.resetVotingState())

    if (response.ok) {
      yield call(loadTask, response.data)
    } else {
      yield call(onError, "There are any available votes in history")
    }

    yield put(VotingActions.updateFetching(false))
  } catch (e) {
    console.error("Error on goBack task", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

export function* sendCandidateDuplicates(action: any) {
  try {
    yield put(VotingActions.updateFetching(true))
    let vote = store.getState().voting.vote
    let duplicates = store.getState().voting.candidate_duplicates

    yield call(API.post, `ds/candidate_duplicates/${vote.parent_check}/`, { duplicates })

    yield put(MessageActions.openSnackbarWithColor("Successfully send!", "success"))
    yield put(VotingActions.updateCandidateDuplicatesSend(vote.parent_check))
    yield put(VotingActions.updateFetching(false))
  } catch (e) {
    console.error("Error on send candidate duplicates", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

export function* finish(action: any) {
  try {
    yield put(VotingActions.updateFetching(true))
    let vote = store.getState().voting.vote

    // @ts-ignore
    const response = yield call(API.post, `voting/vote/${vote.id}/`, {
      candidates: vote.marked_duplicates || [],
    })

    if (response.ok) {
      yield call(loadTask, response.data)
    } else {
      yield call(onError)
    }

    yield put(VotingActions.updateFetching(false))
  } catch (e) {
    console.error("Error on finish task", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

export function* skip(action: any) {
  try {
    yield put(VotingActions.updateFetching(true))
    let vote = store.getState().voting.vote

    // @ts-ignore
    const response = yield call(API.post, `voting/vote/${vote.id}/skip/`)

    if (response.ok) {
      yield call(loadTask, response.data)
    } else {
      yield call(onError)
    }

    yield put(VotingActions.updateFetching(false))
  } catch (e) {
    console.error("Error on skip task", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

export function* loadById(action: any) {
  try {
    yield put(VotingActions.updateFetching(true))
    // @ts-ignore
    const response = yield call(API.get, 'voting/get/' + action.id + '/')

    if (response.ok) {
      yield call(loadTask, response.data)
      yield put(VotingActions.updateConfirmedDuplicates(response.data.confirmed_duplicates))
      yield put(VotingActions.updateFetching(false))
    } else {
      yield call(onError, response.data.detail ?? response.data)
    }
  } catch (e) {
    console.error("Error on load task", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

function* onError(message = "Oops.. Something went wrong!") {
  yield put(MessageActions.openSnackbarWithColor(message, "error"))
  yield put(VotingActions.updateFetching(false))
}
