import React from 'react'
import { connect } from 'react-redux'
import MessageActions from '../../redux/messageRedux'
import MUISnackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

interface iProps {
  snackbar: boolean
  snackbarMessage: string
  snackbarColor: "success" | "warning" | "error" | "info"
  closeSnackbar: () => void
}

function Snackbar(props: iProps) {
  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={props.snackbar}
      autoHideDuration={10000}
      onClose={() => props.closeSnackbar()}
    >
      <MuiAlert
        variant="filled"
        onClose={() => props.closeSnackbar()}
        severity={props.snackbarColor}
      >
        <span dangerouslySetInnerHTML={{ __html: props.snackbarMessage }}></span>
      </MuiAlert>
    </MUISnackbar>
  )
}

const mapStateToProps = ( state : any ) => {
  return {
    snackbar: state.message.snackbar,
    snackbarMessage: state.message.snackbarMessage,
    snackbarColor: state.message.snackbarColor,
  }
}

const mapDispatchToProps = ( dispatch : any ) => {
  return {
    closeSnackbar: () => dispatch(MessageActions.closeSnackbar()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar)
