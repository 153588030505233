import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  auth: require('./authRedux').reducer,
  dispute: require('./disputeRedux').reducer,
  message: require('./messageRedux').reducer,
  voting: require('./votingRedux').reducer,
  users: require('./usersRedux').reducer,
  transactions: require('./transactionsRedux').reducer,
  buckets: require('./bucketsRedux').reducer,
})

export default rootReducer
