import BlacklistImagesPage from './BlacklistImagesPage'
import BucketsListPage from './managers/BucketsListPage'
import ConfirmEmailPage from './ConfirmEmailPage'
import DashboardPage from './DashboardPage'
import DeduplicationPage from './managers/DeduplicationPage'
import DisputePage from './DisputePage'
import ErrorPage from './ErrorPage'
import ManageDisputeListPage from './managers/ManageDisputeListPage'
import ManageDisputePage from './managers/ManageDisputePage'
import MatchingPerformancePage from './managers/MatchingPerformancePage'
import PasswordResetPage from './PasswordResetPage'
import PerformancePage from './managers/PerformancePage'
import ReferenceEvaluationRequestsPage from './managers/ReferenceEvaluationRequestsPage'
import SigninPage from './SigninPage'
import SignupPage from './SignupPage'
import UsersListPage from './managers/UsersListPage'
import VoteHistoryPage from './managers/VoteHistoryPage'
import VotingPage from './VotingPage'

const exportedObject = {
  BlacklistImagesPage,
  BucketsListPage,
  ConfirmEmailPage,
  DashboardPage,
  DeduplicationPage,
  DisputePage,
  ErrorPage,
  ManageDisputeListPage,
  ManageDisputePage,
  MatchingPerformancePage,
  PasswordResetPage,
  PerformancePage,
  ReferenceEvaluationRequestsPage,
  SigninPage,
  SignupPage,
  UsersListPage,
  VoteHistoryPage,
  VotingPage,
}

export default exportedObject
