import React, {useEffect, useState} from 'react'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from '@mui/material';
import API, {iResponse} from '../services/api'

import Page from '../components/Layout/Page'
import {useLocation} from "react-router-dom";
import {objectMap} from '../utils/functions'
import CustomImageGallery from "../components/blacklistImage/ImageGallery"
import CustomBlacklistingDialog from "../components/blacklistImage/BlacklistedDialog";
import {
  blacklistModelObj,
  blacklistRequestImagesData,
  defaultRequestBody,
  ImageData
} from '../components/blacklistImage/Interfaces'

function BlacklistImagesPage() {
  const [listingId, setListingId] = useState('')
  const [bucketID, setBucketID] = useState(0)
  const [requestID, setRequestID] = useState('')
  const [baseListingImages, setBaseListingImages] = useState<ImageData>({})
  const [selectedBaseImages, setSelectedBaseImages] = useState<string[]>([])
  const [baseListingData, setBaseListingData] = useState<ImageData>({})

  const [similarImages, setSimilarImages] = useState<ImageData>({})
  const [selectedSimilarImages, setSelectedSimilarImages] = useState<string[]>([])

  const [isLoading, setIsLoading] = useState(false)
  const [categoryID, setCategoryID] = useState<string>('')
  const [allSelected, setAllSelected] = useState(false)
  const [allSimilarSelected, setAllSimilarSelected] = useState(false)
  const [open, setOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState<iResponse>();
  const [finishedSimilarImageExtraction, setFinishedSimilarImageExtraction] = useState(false)

  const handleInputListing = (event: React.ChangeEvent<HTMLInputElement>) => setListingId(event.target.value)

  const handleFindCandidateImages = async () => {
    try {
      const searchParams = new URLSearchParams({ listing_id: listingId })
      const fullUrl = `blacklisting/select_first_listing/?${searchParams}`

      try {
        setIsLoading(true)
        const response = await API.get(fullUrl)
        setBaseListingImages(response.data.images)
        setBucketID(response.data.redis_bucket_id)
        setRequestID(response.data.request_id)
      } finally {
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const toggleImageSelection = (key: string) => {
    if (selectedBaseImages.includes(key)) {
      setSelectedBaseImages(selectedBaseImages.filter((imageKey) => imageKey !== key))
    } else {
      setSelectedBaseImages([...selectedBaseImages, key])
    }
  }

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedBaseImages([])
    } else {
      setSelectedBaseImages(Object.keys(baseListingImages))
    }
    setAllSelected(!allSelected)
  }

  const toggleSimilarImageSelection = (key: string) => {
    if (selectedSimilarImages.includes(key)) {
      setSelectedSimilarImages(selectedSimilarImages.filter((imageKey) => imageKey !== key))
    } else {
      setSelectedSimilarImages([...selectedSimilarImages, key])
    }
  }

  const handleSelectAllSimilar = () => {
    const candidatesPool: ImageData = {...similarImages, ...baseListingData}
    if (allSimilarSelected) {
      setSelectedSimilarImages([])
    } else {
      setSelectedSimilarImages(Object.keys(candidatesPool))
    }
    setAllSimilarSelected(!allSimilarSelected)
  }

  const processSelectedImages = async () => {
    const initialData: ImageData = {}
    selectedBaseImages.forEach((hash) => {
      if (baseListingImages.hasOwnProperty(hash)) {
        initialData[hash] = baseListingImages[hash]
      }
    })
    setBaseListingData(initialData)
    const selectedImageUrls: any[] = []
    const imagesDict: ImageData = {};
    selectedBaseImages.forEach((key) => {
      if (!(baseListingImages) || baseListingImages[key] && baseListingImages[key]?.integer_hash) {
        if (baseListingImages) { // @todo check
          selectedImageUrls.push(baseListingImages[key]?.integer_hash)
          imagesDict[key] = baseListingImages[key]
        }
      }
    })

    const requestBody: defaultRequestBody = {
      redis_bucket_id: bucketID,
      integer_hashes: selectedImageUrls,
    }

    const blacklistImageRequest = {
      redis_bucket_id: bucketID,
      images: imagesDict,
    }
    try {
      setIsLoading(true)
      await API.post('blacklist/image/create_request/',
          {request_id: requestID, listing_id: listingId, image_ids: blacklistImageRequest})

      const similar_photos = await API.post('blacklisting/get_similar_photos/', requestBody)
      if (!similar_photos.ok) console.error('!response.ok', similar_photos)
      setSimilarImages(similar_photos.data.images)
    } finally {
      setSelectedBaseImages([])
      setIsLoading(false)
      setFinishedSimilarImageExtraction(true)
    }
  }

  const blacklistImages = async () => {
    const candidatesPool: ImageData = {...similarImages, ...baseListingData}
    // const selectedImageData: any[] = []
    const myBodyList: blacklistModelObj[] = []

    for (const key of selectedSimilarImages) {
      if (!(candidatesPool) || candidatesPool[key]) {
        // selectedImageData.push(key)

        const meta: Record<string, any> = {'total_count': candidatesPool[key].phash_count}
        const my_body: blacklistModelObj = {
          photo_id: candidatesPool[key].photo_id,
          listing_id: candidatesPool[key].listing_id,
          source_id: candidatesPool[key].source_id,
          thumbnail: candidatesPool[key].url,
          original_url: candidatesPool[key].photo_url,
          phash: key,
          category_id: Number(categoryID),
          meta_data: meta,
          request_id: requestID
        }
        myBodyList.push(my_body)
      }
    }

    const response = await API.post('blacklisting/bulk_create/', {'images': myBodyList})
    setApiResponse(response);
    if (!response.ok) console.error('!response.ok', response)

    setOpen(true);
  }

  const handleClose = () => {
    setSelectedSimilarImages([])
    setOpen(false);
  };

const location = useLocation()
const queryParameters = new URLSearchParams(location.search)
const toolsRequestID = queryParameters.get('request_id')
const ready_to_blacklist = selectedSimilarImages.length === 0 || !categoryID;

useEffect(() => {
  async function fetchData() {
    try {
      const apiUrl = `blacklist/image/${toolsRequestID}`;
      const response = await API.get(apiUrl) ;
      const blacklist_request_data = response.data.request_data;

      setListingId(blacklist_request_data.listing_id)
      setBucketID(blacklist_request_data.redis_bucket_id)
      setBaseListingData(blacklist_request_data.images)
      setBaseListingImages(blacklist_request_data.images)
      setSelectedBaseImages(Object.keys(baseListingImages))

      const blacklist_request_images: blacklistRequestImagesData = blacklist_request_data.images;
      const selectedImageUrls: any[] = []

      objectMap(blacklist_request_images, (row: blacklistRequestImagesData) => {
      selectedImageUrls.push(row.integer_hash)
    })

      const requestBody: defaultRequestBody = {
      redis_bucket_id: blacklist_request_data.redis_bucket_id,
      integer_hashes: selectedImageUrls,
    }
      try {
      setIsLoading(true)

      const similar_photos = await API.post('blacklisting/get_similar_photos/', requestBody)
      if (!similar_photos.ok) console.error('!response.ok', similar_photos)
      setSimilarImages(similar_photos.data.images)
    } finally {
      setSelectedBaseImages([])
      setIsLoading(false)
        setFinishedSimilarImageExtraction(true)
    }

    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  toolsRequestID && fetchData();

  }, [toolsRequestID]);

  return (
    <Page className="blacklisstingPage" title="Blacklist Images Page">
      <Stack
        direction="row"
        alignItems="baseline"
        spacing={2}
      >
        <TextField
          required
          id="outlined-required"
          label="Required"
          defaultValue="Listing ID"
          value={listingId}
          onChange={handleInputListing}
          disabled={isLoading}
        />
        <Button variant="contained" size="large" onClick={handleFindCandidateImages} disabled={!listingId || isLoading}>
          Submit
        </Button>
      </Stack>

      {isLoading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
            <p className="spinner-text">Loading data. Please wait ...</p>
          </div>
      ) : (typeof baseListingImages === "object" && Object.keys(baseListingImages).length === 0) ? (
          <p>Enter a Listing ID and click Submit to load images.</p>
      ) : ((typeof similarImages === "object" && finishedSimilarImageExtraction) ? (
          <>
            <CustomImageGallery
                listingId={listingId}
                baseListingData={baseListingData}
                selectedSimilarImages={selectedSimilarImages}
                toggleSimilarImageSelection={toggleSimilarImageSelection}
            />
            <br/>
            <div style={{border: '2px solid gray'}}>
              <h3>Similar Images</h3>
              {(Object.keys(similarImages).length !== 0) ? (
                  <CustomImageGallery
                      baseListingData={similarImages}
                      selectedSimilarImages={selectedSimilarImages}
                      toggleSimilarImageSelection={toggleSimilarImageSelection}
                  />
              ) : (<h1>No Similar Images!</h1>)}
            </div>
            <br/>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <FormControl sx={{minWidth: 120}}>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categoryID}
                  label="Category"
                  onChange={(event: SelectChangeEvent) => setCategoryID(event.target.value)}
                >
                  <MenuItem value={1}>Image is OK</MenuItem>
                  <MenuItem value={2}>Placeholder</MenuItem>
                  <MenuItem value={3}>Repetitive</MenuItem>
                </Select>
              </FormControl>
              <span style={{margin: "0 10px"}}/>
              <Button variant="contained" onClick={() => handleSelectAllSimilar()}>
                {allSimilarSelected ? "Deselect All" : "Select All"}
              </Button>
              <span style={{margin: "0 10px"}}/>
              <Button variant="contained" onClick={blacklistImages} disabled={ready_to_blacklist}>
                Blacklist {selectedSimilarImages.length} Images
              </Button>
              <CustomBlacklistingDialog
                open={open}
                handleClose={handleClose}
                apiResponse={apiResponse}
                selectedSimilarImages={selectedSimilarImages}
              />
            </div>
          </>
          ) : (
              <Box sx={{border: '2px outset grey'}}>
                <CustomImageGallery
                    listingId={listingId}
                    baseListingData={baseListingImages}
                    selectedSimilarImages={selectedBaseImages}
                    toggleSimilarImageSelection={toggleImageSelection}
                />
            <Button variant="contained" onClick={() => handleSelectAll()}>
              {allSelected ? "Deselect All" : "Select All"}
            </Button>
            <span style={{margin: "0 10px"}}/>
            <Button variant="contained" onClick={processSelectedImages} disabled={selectedBaseImages.length === 0}>
              Process Selected Images
            </Button>
          </Box>
          )
      )
      }
    </Page>
  )
}

export default BlacklistImagesPage
