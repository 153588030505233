import React, { useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import TabBar from './TabBar'
import Box from '@mui/material/Box'
import useMobileService from '../../utils/mobileService'
import CssBaseline from '@mui/material/CssBaseline'

type Props = {
  children: React.ReactNode,
  title?: string
}

export default function MainLayout(props: Props) {
  const [open, setOpen] = useState(false)
  const isMobile = useMobileService()
  const { children, title } = props;

  if (isMobile) {
    return (
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <CssBaseline />
        <Header title={title} />
        <Box flex={1} overflow="scroll">
          {children}
        </Box>
        <TabBar />
      </Box>
    )
  }

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <CssBaseline />
      <Header title={title} onMenuClick={() => setOpen(true)} />
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <Box flex={1} display="flex" id="layout-box">
        {children}
      </Box>
      <Footer />
    </Box>
  );
}
