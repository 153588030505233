import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import API from '../services/api'
import { buildUrls } from '../utils/functions'


export interface iStatistic {
  total: number
  finished: number
  resolution_avg: number
  reference_error_avg: number
  available_evaluation_requests: number
}

export interface iPerformance {
  total: number
  finished: number
  resolution_avg: number
  reference_error_avg: number
	date: string
}

interface UsePerformanceState {
  loading: boolean
  list: iPerformance[]
  statistic: iStatistic

  loadPerformance: (user_id: number) => void
  loadStatistic: (user_id: number) => void
  get_today: () => iPerformance|undefined
}

const date = new Date()
date.setDate(date.getDate() - 7)
const filter = {
  items: [
    {
      columnField: 'created_at',
      operatorValue: '>',
      value: date.toISOString().split('T')[0],
    },
  ],
}

const INIT_STATISTIC: iStatistic = {
  total: 0,
  finished: 0,
  resolution_avg: 0,
  reference_error_avg: 0,
  available_evaluation_requests: 0,
}


export const UsePerformanceStore = create<UsePerformanceState>()(
  devtools(
    persist(
      (set, get) => ({
        loading: false,
        list: [],
        statistic: INIT_STATISTIC,

        loadPerformance: async(user_id) => {
          set({ loading: true })
          try {
            const response = await API.get(buildUrls(`users/${user_id}/performance/`, { filter }))

            if (response.ok) {
              set({ list: response.data })
            } else {
              set({ list: [] })
              console.error('!response.ok', response)
            }
          } catch (e) {
            console.log('load performance:', e)
          } finally {
            set({ loading: false })
          }
        },

        loadStatistic: async(user_id) => {
          set({ loading: true })
          try {
            const response = await API.get(buildUrls(`users/${user_id}/statistic/`, { filter }))

            if (response.ok) {
              set({ statistic: response.data })
            } else {
              console.error('!response.ok', response)
            }
          } catch (e) {
            console.log('load statistic:', e)
          } finally {
            set({ loading: false })
          }
        },

        get_today: () => {
          try {
            return get().list[get().list.length - 1]
          } catch (e) {
            console.debug('Performance list not loaded', e)
            return undefined
          }
        },
      }),
      {
        name: 'performance-store',
      }
    )
  )
)
