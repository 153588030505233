import { call, put } from 'redux-saga/effects'
import DisputeActions from '../redux/disputeRedux'
import VoteActions from '../redux/votingRedux'
import MessageActions from '../redux/messageRedux'
import API from '../services/api'

export function* select(action: any) {
  try {
    yield put(DisputeActions.resetState())
    yield put(VoteActions.resetVotingState())
    yield put(DisputeActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.get, 'disputes/select/')

    if (response.ok) {
      yield put(DisputeActions.updateList(response.data))
      yield call(get_statistic, action)
    } else {
      yield put(DisputeActions.resetState())
      yield put(MessageActions.openSnackbarWithColor(response.data, "info"))
    }

    yield put(DisputeActions.updateFetching(false))
    window.scrollTo(0, 0)
  } catch (e) {
    console.error("Error on select next dispute", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

export function* get_statistic(action: any) {
  try {
    yield put(DisputeActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.get, 'disputes/statistic/')

    if (response.ok) {
      yield put(DisputeActions.updateStatistic(response.data))
    } else {
      yield put(MessageActions.openSnackbarWithColor(response.data, "info"))
    }

    yield put(DisputeActions.updateFetching(false))
  } catch (e) {
    console.error("Error on get statistic", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }
}

function* onError(message = "Oops.. Something went wrong!") {
  yield put(MessageActions.openSnackbarWithColor(message, "error"))
  yield put(DisputeActions.updateFetching(false))
}
