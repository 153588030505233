import React from 'react'
import { shallow } from 'zustand/shallow'
import {
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import {
  Send as SendIcon,
} from '@mui/icons-material'

import { UseMessageStore } from '../../../stores/UseMessageStore'

interface iProps {
  recipient?: number
}

export default function CreateMessageInline({ recipient }: iProps) {
  const [message, setMessage] = React.useState('')
  const { createMessage } = UseMessageStore((state) => ({
    createMessage: state.createMessage,
  }), shallow)

  const handleCreate = () => {
    createMessage(message, recipient)
    setMessage('')
  }

  return (
    <TextField
      placeholder="Message"
      fullWidth={true}
      multiline
      value={message}
      onChange={(event) => setMessage(event.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton sx={{ "&:hover": { color: (theme) => theme.palette.primary.main } }}
              aria-label="Send message"
              onClick={handleCreate}
              edge="end"
            >
              <SendIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
