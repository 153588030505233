import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import API from '../services/api'
import { buildUrls } from '../utils/functions'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../utils/constants';
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";


export interface iVoting {
  id: number
  status: number
	created_at: string
  updated_at: string
  ended_at: string
  parent_check: number
  user: number
  transaction: number
  resolution: number
  is_reference_vote: boolean
  dispute_ids: number[]
}

interface VotingState {
  loading: boolean
  count: number
  list: iVoting[]

  page: number
  setPage: (page: number) => void

  pageSize: number
  setPageSize: (pageSize: number) => void

  sort: GridSortModel
  setSort: (sort: GridSortModel) => void

  filter: GridFilterModel | undefined
  setFilter: (filter: GridFilterModel) => void
  clearFilter: () => void

  loadVoteHistory: () => void
}

export const VotingStore = create<VotingState>()(
  devtools(
    persist(
      (set, get) => ({
        loading: false,
        count: 0,
        list: [],

        page: DEFAULT_PAGE,
        setPage: (page) => set({ page }),

        pageSize: DEFAULT_PAGE_SIZE,
        setPageSize: (pageSize) => set({ pageSize, page: DEFAULT_PAGE }),

        sort: [],
        setSort: (sort) => set({ sort }),

        filter: undefined,
        setFilter: (filter) => set({ filter }),
        clearFilter: () => set({ filter: undefined }),

        loadVoteHistory: async() => {
          set({ loading: true })
          try {
            const filters = {
              page: get().page,
              pageSize: get().pageSize,
              sort: get().sort,
              filter: get().filter,
            };

            const response = await API.get(buildUrls(`voting/history/`, filters))

            if (response.ok) {
              set({
                count: response.data.count,
                list: response.data.results,
              })
            } else {
              set({ count: 0, list: [] })
              console.error('!response.ok', response)
            }
          } catch (e) {
            console.log('load vote history:', e)
          } finally {
            set({ loading: false })
          }
        },
      }),
      {
        name: 'voting-store',
      }
    )
  )
)
