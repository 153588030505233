import { GridSortItem } from '@mui/x-data-grid'

// Defaults
export const DASH: string = '—'
export const METER_SQUARE = 'm²';
export const PAGE_SIZES: number[] = [10, 20, 50, 100]
export const DEFAULT_PAGE: number = 0
export const DEFAULT_PAGE_SIZE: number = 20

// Ranges
export const DAY_LIMIT_FOR_RANGE: number = 40
export const DAY_DEFAULT_RANGE: number = 7
export const MIN_DEFAULT_RANGE: number = 3

// Datetime
export const DAY_IN_MS: number = 24 * 60 * 60 * 1000

export const SORT_BY_NEWEST: GridSortItem = {
  field: 'created_at',
  sort: 'desc',
}
export const SORT_BY_OLDEST: GridSortItem = {
  field: 'created_at',
  sort: 'asc',
}
