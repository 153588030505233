import React from 'react'
import { Redirect } from 'react-router-dom'
import LayoutRoute from './LayoutRoute'
import { store } from '../../redux/store'

export function ManageRoute(route: any) {
    const user = store.getState().auth.user
    if (user && user?.permissions?.allow_manage && !user?.is_ban) {
        return <LayoutRoute {...route} />
    }
    return <Redirect to={{ pathname: '/' , state: { from: route.location.pathname}}} />
}

export function UserManagerRoute(route: any) {
    const user = store.getState().auth.user
    if (user && user.permissions.allow_users && !user?.is_ban) {
        return <LayoutRoute {...route} />
    }
    return <Redirect to={{ pathname: '/' , state: { from: route.location.pathname}}} />
}

export function DisputeManagerRoute(route: any) {
    const user = store.getState().auth.user
    if (user && user.permissions.allow_dispute && !user?.is_ban) {
        return <LayoutRoute {...route} />
    }
    return <Redirect to={{ pathname: '/' , state: { from: route.location.pathname}}} />
}
