import { call, put } from 'redux-saga/effects'

import DisputeActions from '../redux/disputeRedux'
import TransactionsActions from '../redux/transactionsRedux'
import MessageActions from '../redux/messageRedux'
import API from '../services/api'
import { buildUrls, objectMap } from '../utils/functions'
import { capitalizeFirstLetter } from '../utils/formatters'

function getUrl(type: string) {
  const items = type.split('_')
  const name = items[items.length - 1].toLowerCase()
  return name + (name[name.length-1] === 's' ? '' : 's') + '/'
}

function getAction(type: string) {
  const items = type.split('_')
  switch (items[items.length - 1]) {
    case 'DISPUTE':
      return DisputeActions
    case 'TRANSACTIONS':
      return TransactionsActions
  }
  throw Error('Action not found!')
}

export function* create(action: any) {
  try {
    const url = getUrl(action.type)
    const actions = getAction(action.type)

    yield put(actions.updateFetching(true))
    // @ts-ignore
    const response = yield call(API.post, url, action.data)

    if (response.ok) {
      yield put(MessageActions.openSnackbarWithColor('Successfully created!', 'success'))
    } else {
      let messageList: string[] = []
      objectMap(response.data, (value: string, index: string) => {
        messageList.push(`${capitalizeFirstLetter(index)}: ${value}`)
      })
      yield put(MessageActions.openSnackbarWithColor(messageList.join('<br>'), 'error'))
    }

    yield put(actions.updateFetching(false))
  } catch (e) {
    console.error('Error on restSagas.list', e)
  }
}

export function* list(action: any) {
  try {
    const url = getUrl(action.type)
    const actions = getAction(action.type)

    yield put(actions.updateFetching(true))
    // @ts-ignore
    const response = yield call(API.get, buildUrls(url, action))

    if (response.ok) {
      yield put(actions.updateCount(response.data?.count ? response.data.count : response.data.length))
      yield put(actions.updateList(response.data?.results ? response.data.results : response.data))
    } else {
      console.error('Error on restSagas.list (action, response):', action, response)
      yield put(actions.updateCount(0))
      yield put(actions.updateList([]))
    }

    yield put(actions.updateFetching(false))
  } catch (e) {
    const message = (e as Error).message;
    console.error('Error on restSagas.list', message)
  }
}

export function view(action: any) {
  alert('not realized')
}

export function* update(action: any) {
  try {
    const url = getUrl(action.type) + action.id + '/'
    const actions = getAction(action.type)

    yield put(actions.updateFetching(true))
    // @ts-ignore
    const response = yield call(API.patch, url, action.data)

    if (response.ok) {
      yield put(MessageActions.openSnackbarWithColor('Successfully updated!', 'success'))
    } else {
      const errors = Object.values(response.data)
      const message = errors.length ? errors.join('<br>') : 'Not updated!'
      yield put(MessageActions.openSnackbarWithColor(message, 'error'))
    }

    yield put(actions.updateFetching(false))
  } catch (e) {
    console.error('Error on restSagas.list', e)
  }
}
