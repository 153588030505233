import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { GridFilterModel } from '@mui/x-data-grid'

import API from '../services/api'
import { iSimpleUser } from '../redux/authRedux'
import { buildUrls } from '../utils/functions'

export interface iTransaction {
  user_id: number
  match_count: number
  count_total: number
  quality: number
  full_injection_error_rate: number
  created_at: string
}

export interface iTransactionPerformance {
  user: iSimpleUser,
  transactions: {
    [day: string]: {
      [type: number]: iTransaction,
    },
  },
}

interface iTransactionState {
  loading: boolean
  performance_list: any
  loadPerformance: (filter: GridFilterModel) => void
}


export const UseTransactionStore = create<iTransactionState>()(
  devtools(
    persist(
      (set, get) => ({
        transactions: [],
        loading: false,

        performance_list: [],
        loadPerformance: async(filter) => {
          set({ loading: true })
          try {
            const response = await API.get(buildUrls('transactions/performance/', {
              filter,
            }))

            if (response.ok) {
              set({ performance_list: response.data })
            }
            else console.error('!response.ok', response)
          } catch (e) {
            console.log('loadMessages', e)
          } finally {
            set({ loading: false })
          }
        },
      }),
      {
        name: 'transaction-store',
      }
    )
  )
)
