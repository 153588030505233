import React, { useEffect } from 'react'
import { shallow } from 'zustand/shallow'

import {
  Box,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { DASH, DAY_DEFAULT_RANGE } from '../../utils/constants'
import { dateToISO } from '../../utils/formatters'
import {
  validateDateRange,
  objectMap,
} from '../../utils/functions'
import { UseMatchingPerformanceStore } from '../../stores/UseMatchingPerformanceStore'
import { iPerformance } from '../../stores/UsePerformanceStore'

import Page from '../../components/Layout/Page'


interface iState {
  filter: GridFilterModel
}

const END_DATE = new Date()
const START_DATE = new Date()
START_DATE.setDate(END_DATE.getDate() - DAY_DEFAULT_RANGE)

const INITIAL_STATE: iState = {
  filter: {
    items: [
      {
        columnField: 'created_at',
        operatorValue: '>',
      },
      {
        columnField: 'created_at',
        operatorValue: '<',
      },
    ]
  }
}

function MatchingPerformancePage() {
  const [startDate, setStartDate] = React.useState<Date>(START_DATE)
  const [endDate, setEndDate] = React.useState<Date>(END_DATE)
  const [state, setState] = React.useState<iState>(INITIAL_STATE)

  const { loading, list, load } = UseMatchingPerformanceStore((state) => state, shallow)

  useEffect(() => {
    if (getError()) return
    setState({...INITIAL_STATE, filter: {
      items: [
        {
          ...INITIAL_STATE.filter.items[0],
          value: dateToISO(startDate),
        },
        {
          ...INITIAL_STATE.filter.items[1],
          value: dateToISO(endDate),
        }
      ]
    }})
  }, [startDate, endDate])

  useEffect(() => {
    if (!state.filter.items[0].value || !state.filter.items[1].value) return
    load(state.filter)
  }, [state.filter])

  const getColumns = (): GridColDef[] => {
    if (!list.length) return []

    let columns = [
      {
        field: 'user',
        headerName: 'User ID Name',
        type: 'number',
        minWidth: 250,
        cellClassName: 'MuiDataGrid-cell-stick-left',
        filterable: false,
        valueGetter: (params: any) => `#${params.value.id} ${params.value.first_name} ${params.value.last_name}`
      },
    ]

    objectMap(list[0].list, (dayPerformance: iPerformance) => {
      const column: GridColDef = {
        field: `${dayPerformance.date}`,
        headerName: dayPerformance.date,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          const data = params.row.list[params.field]

          if (data.total) {
            return <>
              <span title="Mistakes" className="badge pull-rt">
                {(100 - +data.resolution_avg).toFixed(2)}%
              </span>
              <b title="Processed / Total">{data.finished} / {data.total}</b>
            </>
          }

          return DASH
        },
        cellClassName: (params) => {
          const data = params.row.list[params.field]

          if (data.resolution_avg) {
            if (data.resolution_avg > 95) return 'bg-success'
            if (data.resolution_avg > 90) return 'bg-warning'
            return 'bg-danger'
          }
          return ''
        },
      }

      // @ts-ignore
      columns.push(column)
    })

    const totalColumn: GridColDef = {
      field: 'total',
      headerName: 'Total',
      type: 'string',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        let processed = 0
        let finished = 0
        objectMap(params.row.list, (day: iPerformance) => {
          processed += day?.finished || 0
          finished += day?.total || 0
        })
        return <b title="Processed / Total">{processed} / {finished}</b>
      },
    };
    // @ts-ignore
    columns.push(totalColumn)

    return columns
  }

  const getError = () => validateDateRange(startDate, endDate) ? true : false

  return (
    <Page
      className="matchingPerformance"
      title="Matching performance"
      description="*Only for users which mark as `is_annotator`"
    >
      <Paper elevation={3} style={{ marginBottom: 24, padding: 16, justifyContent: "center" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box display="flex" flexDirection="row">
            <DatePicker
              inputFormat="E dd MMM yyyy"
              label="Start date"
              value={startDate}
              onChange={(value: any) => setStartDate(value)}
              renderInput={(params: any) => <TextField
                {...params}
                error={getError()}
                helperText={validateDateRange(startDate, endDate)}
              />}
            />
            <Box display="flex" alignItems="center" sx={{ mx: 2 }}> to </Box>
            <DatePicker
              inputFormat="E dd MMM yyyy"
              label="End date"
              value={endDate}
              onChange={(value: any) => setEndDate(value)}
              renderInput={(params: any) => <TextField {...params} error={getError()} />}
            />
          </Box>
        </LocalizationProvider>

        {loading && <LinearProgress />}
        <DataGrid
          rows={list}
          getRowId={(row) => row.user.id}
          columns={getColumns()}
          autoHeight={true}
          loading={loading}
          disableColumnFilter={true}
          // styling
          disableVirtualization={true}
          sx={{mt: 2}}
          components={{
            NoRowsOverlay: () => (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                mt: 8,
              }}>
                <Typography color="error">Any performance statistic yet</Typography>
              </Box>
            ),
          }}
        />
      </Paper>
    </Page>
  )
}

export default MatchingPerformancePage;
