import * as React from 'react'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { Help } from '@mui/icons-material'

interface iProps {
  title: string
  value: string
  tooltip?: string
}

export default function InfoBadge(props: iProps) {
  const renderTooltip = () => {
    if (props.tooltip) {
      return (
        <Tooltip title={props.tooltip}>
          <IconButton><Help /></IconButton>
        </Tooltip>
      )
    }
  }

  return (
    <React.Fragment>
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        gutterBottom
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: 40 }}
      >
        {props.title}
        {renderTooltip()}
      </Typography>
      <Typography component="p" variant="h4">
        {props.value}
      </Typography>
    </React.Fragment>
  );
}
