import AuthenticatedRoute from './AuthenticatedRoute'
import BlackListRoute from './BlackListRoute'
import LayoutRoute from './LayoutRoute'
import NonAuthenticatedRoute from './NonAuthenticatedRoute'
import VotingRoute from './VotingRoute'
import { ManageRoute, DisputeManagerRoute, UserManagerRoute } from './ManagerRoute'

const exportedObject = {
  AuthenticatedRoute,
  BlackListRoute,
  DisputeManagerRoute,
  LayoutRoute,
  ManageRoute,
  NonAuthenticatedRoute,
  UserManagerRoute,
  VotingRoute,
}

export default exportedObject
