import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  goNext: null,
  goBack: null,
  sendCandidateDuplicates: null,
  finish: null,
  skip: null,
  loadById: ['id'],

  addMatch: ['id'],
  removeMatch: ['id'],
  addCandidateDuplicate: ['id'],
  removeCandidateDuplicate: ['id'],
  resetCandidateDuplicates: null,
  updateCandidateDuplicatesSend: ['id'],
  updateVote: ['vote'],
  updateTarget: ['target'],
  updateCandidates: ['candidates'],
  updateConfirmedDuplicates: ['confirmed_duplicates'],
  updateFetching: ['fetching'],
  resetVotingState: null,
})

export const VotingTypes = Types
export default Creators

/* ------------- Initial State ------------- */

type VotingState = {
  vote: any,
  target: any,
  candidates: any,
  confirmed_duplicates: number[],
  candidate_duplicates: number[],
  parent_check_of_send_candidate_duplicates: number,
  fetching: boolean,
}

export const INITIAL_STATE = Immutable({
  vote: false,
  target: false,
  candidates: false,
  confirmed_duplicates: [],
  candidate_duplicates: [],
  parent_check_of_send_candidate_duplicates: 0,
  fetching: false,
})

/* ------------- Reducers ------------- */

export const updateVote = (state: any, { vote }: any) =>
  state.merge({ vote })

export const updateTarget = (state: any, { target }: any) =>
  state.merge({ target })

export const updateCandidates = (state: any, { candidates }: any) =>
  state.merge({ candidates })

export const updateConfirmedDuplicates = (state: any, { confirmed_duplicates }: any) =>
  state.merge({ confirmed_duplicates })

export const updateFetching = (state: any, { fetching }: VotingState) =>
  state.merge({ fetching })

export const resetVotingState = (state: any) =>
  state.merge({
    vote: INITIAL_STATE.vote,
    target: INITIAL_STATE.target,
    candidates: INITIAL_STATE.candidates,
    confirmed_duplicates: INITIAL_STATE.confirmed_duplicates,
    candidate_duplicates: INITIAL_STATE.candidate_duplicates,
  })

export const addMatch = (state: any, { id }: any) => {
  let marked_duplicates = state.vote.marked_duplicates || []
  marked_duplicates = [...marked_duplicates, id]

  let candidates: number[] = []
  state.candidates.map((candidate: any, index: number) =>
    candidates.push(Object.assign({}, candidate, { marked: marked_duplicates.includes(candidate.id) }))
  )

  return state.merge({
    vote: { ...state.vote, marked_duplicates },
    candidates,
  })
}

export const removeMatch = (state: any, { id }: any) => {
  let marked_duplicates = state.vote.marked_duplicates || []
  if (marked_duplicates?.length) {
    marked_duplicates = marked_duplicates.filter((element: number) => element !== id)
  }

  let candidates: number[] = []
  state.candidates.map((candidate: any, index: number) =>
    candidates.push(Object.assign({}, candidate, { marked: marked_duplicates.includes(candidate.id) }))
  )

  return state.merge({
    vote: { ...state.vote, marked_duplicates },
    candidates,
  })
}

export const addCandidateDuplicate = (state: any, { id }: any) =>
  state.merge({ candidate_duplicates: [...state.candidate_duplicates, id] })

export const removeCandidateDuplicate = (state: any, { id }: any) =>
  state.merge({ candidate_duplicates: state.candidate_duplicates.filter((e: any) => e !== id) })

export const resetCandidateDuplicates = (state: any) =>
  state.merge({ candidate_duplicates: INITIAL_STATE.candidate_duplicates })

export const updateCandidateDuplicatesSend = (state: any, { id }: any) =>
  state.merge({
    candidate_duplicates: INITIAL_STATE.candidate_duplicates,
    parent_check_of_send_candidate_duplicates: id,
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_VOTE]: updateVote,
  [Types.UPDATE_TARGET]: updateTarget,
  [Types.UPDATE_CANDIDATES]: updateCandidates,
  [Types.UPDATE_CONFIRMED_DUPLICATES]: updateConfirmedDuplicates,
  [Types.UPDATE_FETCHING]: updateFetching,
  [Types.RESET_VOTING_STATE]: resetVotingState,
  [Types.ADD_MATCH]: addMatch,
  [Types.REMOVE_MATCH]: removeMatch,
  [Types.ADD_CANDIDATE_DUPLICATE]: addCandidateDuplicate,
  [Types.REMOVE_CANDIDATE_DUPLICATE]: removeCandidateDuplicate,
  [Types.RESET_CANDIDATE_DUPLICATES]: resetCandidateDuplicates,
  [Types.UPDATE_CANDIDATE_DUPLICATES_SEND]: updateCandidateDuplicatesSend,
})
