import { shallow } from 'zustand/shallow'

import { Box, Button, ButtonGroup, Skeleton, Stack } from '@mui/material'

import { DASH } from '../../utils/constants'
import { UsePerformanceStore } from '../../stores/UsePerformanceStore'


function Statistic() {
  const { today, available_evaluation_requests } = UsePerformanceStore((state) => ({
    today: state.get_today(),
    available_evaluation_requests: state.statistic.available_evaluation_requests,
  }), shallow)

  return (
    <Box
      className="estate-info"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
          m: 1,
        },
      }}
    >
      {today ? (
        <ButtonGroup
          variant="outlined"
          size="small"
          color="secondary"
          disableRipple={true}
          disableFocusRipple={true}
        >
          <Button>Processed: {today.finished}</Button>
          <Button>Total: {today.total}</Button>
          <Button>Mistakes: {today.resolution_avg ? `${(100 - today.resolution_avg).toFixed(2)} %` : DASH}</Button>
          <Button>Error Rate: {today.reference_error_avg ? (today.reference_error_avg).toFixed(2) + ' %' : DASH}</Button>
          <Button>Available Listings: {(available_evaluation_requests).toLocaleString('pt-PT')}</Button>
        </ButtonGroup>
      ) : (
        <Stack direction="row" spacing={2}>
          <Skeleton variant="rectangular" width={120} height={30} />
          <Skeleton variant="rectangular" width={120} height={30} />
          <Skeleton variant="rectangular" width={120} height={30} />
          <Skeleton variant="rectangular" width={120} height={30} />
          <Skeleton variant="rectangular" width={120} height={30} />
        </Stack>
      )}
    </Box>
  )
}
export default Statistic
