import { DASH } from './constants'

export const currencyGetter = (value: any) => {
  return (parseFloat(value) / 100).toFixed(2)
}

export const currencyFormatter = (value: any, withGetter = false) => {
  if (withGetter) return `$ ` + currencyGetter(value)
  if (value) return `$ ${value}`
  return DASH
}

export const parseCurrencyToInt = (value: any) => {
  if ('string' == typeof value) {
    value = parseFloat(value.replace(',', '.'))
  }
  return value * 100
}

export const dateToISO = (date: Date|null, addDate: number = 0) => {
  if (!date) return null
  const newDate = new Date(date)
  if (addDate !== 0) {
    newDate.setDate(newDate.getDate() + addDate)
  }
  return newDate.toISOString().split('T')[0]
}

export const datetimeFormatter = (value: string|undefined|null) => {
  if (value) {
    let date = new Date(Date.parse(value as string))
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  }
}

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}
