import React from 'react'
import { connect } from 'react-redux'

import { Toolbar, AppBar, Typography, Link, Box } from '@mui/material'
import logo from '../../assets/logo-white.png'

import MessageDropDown from './MessageDropDown'
import ProfileDropDown from './ProfileDropDown'
import { iUser } from '../../redux/authRedux'


interface iProps {
  user: iUser
  title?: string
  onMenuClick?: () => void
}

function Header(props: iProps) {
  return (
    <AppBar position="sticky" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="div"
        >
          <Link href="/">
            <img
              alt="CASAFARI"
              src={logo}
              width="209"
              height="30"
              className="mb-2 mt-2"
            />
          </Link>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {props.user && (
          <Box sx={{ display: 'flex' }}>
            <MessageDropDown />
            <ProfileDropDown />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(Header)
