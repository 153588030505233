import React from 'react'
import {Grid, Divider, Link} from '@mui/material'

const Footer = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={12} style={{textAlign: "center", color: "secondary", padding: 8}}>
          Copyright © {new Date().getFullYear()}{' - '}
          <Link color="inherit" href="https://casafari.com/" target="_blank">
            {process.env.REACT_APP_COMPANY_NAME}
          </Link>
      </Grid>
    </Grid>
  )
}

export default Footer;