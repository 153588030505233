import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { iSimpleUser } from '../redux/authRedux'
import API from '../services/api'
import { buildUrls } from '../utils/functions'


export enum STATUS {
  New = 1,
  Read,
  Answered,
}

export interface iMessage {
  readonly id?: number
  status: number
  readonly created_at?: string
  readonly updated_at?: string
  readonly sender?: iSimpleUser
  text: string
  recipient?: iSimpleUser
}

export interface iChat {
  readonly last_message: string
  readonly sender: iSimpleUser
  readonly recipient_id: number
  readonly unread_count: number
}

interface MessageState {
  messages: iMessage[]
  chats: iChat[]
  unread_messages: number
  loading: boolean
  loadMessages: (chat_with_id?: number) => void
  loadChats: () => void
  loadCounter: () => void
  createMessage: (text: string, recipient_id?: number, withoutUpdate?: boolean) => void
  createChats: (text: string, recipients: iSimpleUser[]) => void
}


export const UseMessageStore = create<MessageState>()(
  devtools(
    persist(
      (set, get) => ({
        messages: [],
        chats: [],
        unread_messages: 0,
        loading: false,

        loadMessages: async(chat_with_id) => {
          set({ loading: true })
          try {
            const response = await API.get(buildUrls('messages/', {
              filter: {
                items: [
                  {
                    columnField: 'chat_with_id',
                    value: chat_with_id
                  }
                ]
              }
            }))

            if (response.ok) set({ messages: response.data })
            else console.error('!response.ok', response)
          } catch (e) {
            console.log('loadMessages', e)
          } finally {
            set({ loading: false })
          }
        },

        loadChats: async() => {
          set({ loading: true })
          try {
            const response = await API.get(`messages/chats/`)
            if (response.ok) set({ chats: response.data })
            else console.error('!response.ok', response)
          } catch (e) {
            console.error('loadChats', e)
          } finally {
            set({ loading: false })
          }
        },

        loadCounter: async() => {
          set({ loading: true })
          try {
            const response = await API.get(`messages/counter/`)
            if (response.ok) set(response.data)
            else console.error('!response.ok', response)
          } catch (e) {
            console.error('loadCounter', e)
          } finally {
            set({ loading: false })
          }
        },

        createMessage: async(text, recipient_id) => {
          set({ loading: true })
          try {
            const response = await API.post('messages/', { text, recipient_id })

            if (response.ok) {
              await get().loadCounter()
              await get().loadMessages(recipient_id)
            }
            else console.error('!response.ok', response)
          } catch (e) {
            console.error('createMessage', e)
          } finally {
            set({ loading: false })
          }
        },

        createChats: async(text, recipients) => {
          set({ loading: true })
          try {
            if (recipients.length) {
              recipients.map(async(recipient) => {
                const response = await API.post('messages/', { text, recipient_id: recipient.id })
                if (!response.ok) console.error('!response.ok', response)
              })
            } else {
              const response = await API.post('messages/', { text, recipient_id: 0 })
              if (!response.ok) console.error('!response.ok', response)
            }

            await get().loadCounter()
            await get().loadChats()
          } catch (e) {
            console.error('createChats', e)
          } finally {
            set({ loading: false })
          }
        }
      }),
      {
        name: 'message-store',
      }
    )
  )
)
