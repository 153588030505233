import ReCAPTCHA from 'react-google-recaptcha'
import React, { RefObject } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  Grid,
  InputAdornment,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Avatar,
  Box,
  Link,
  FormControl,
  FormHelperText,
  Container,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material'

import AuthActions from '../redux/authRedux'
import { store } from '../redux/store'

type Props = {
  fetching: boolean,
  errors: any,
  isCaptcha: boolean,
  login: (email: string, password: string, g_recaptcha_token: any) => void,
}

interface State {
  email: string,
  password: string;
  g_recaptcha_token: any;
  checked: boolean;
  showPassword: boolean;
}

function SigninPage(props: Props) {
  const { fetching, errors, isCaptcha } = props
  const [values, setValues] = React.useState<State>({
    email: '',
    password: '',
    g_recaptcha_token: null,
    checked: false,
    showPassword: false,
  })
  const history = useHistory()
  const historyState: any = history.location.state
  const recaptchaRef: RefObject<any> = React.createRef()
  const recaptchaKey: string = process.env.REACT_APP_RE_CAPTCHA_KEY || ''

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleBooleanChange = (prop: keyof State) => () => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isCaptchaValid = () => {
    if (isCaptcha || errors.g_recaptcha_token) {
      return typeof values.g_recaptcha_token == "string" && values.g_recaptcha_token.length > 0
    }
    return true
  }

  const isFormValid = () => {
    return isCaptchaValid() && values.email && values.password
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.login(values.email, values.password, values.g_recaptcha_token)
    recaptchaRef.current.reset()
  }

  if (store.getState().auth.user) {
    return (
      <Redirect
        to={historyState && historyState.from ? historyState.from : '/'}
      />
    )
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ m: 1 }}
        >
          <TextField
            id="email"
            value={values.email}
            onChange={handleChange('email')}
            required
            fullWidth
            margin="normal"
            autoComplete="email"
            autoFocus
            label="Email Address"
            error={errors.email}
            helperText={values.email == '' ? "Please enter valid email address" : errors.email}
          />
          <TextField
            id="password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            required
            fullWidth
            margin="normal"
            autoComplete="current-password"
            label="Password"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleBooleanChange("showPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
            }}
            error={errors.password}
            helperText={values.password == '' ? 'Password must contain between 8 and 64 characters' : errors.password}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <FormControl
            sx={{
              display: isCaptcha ? 'block' : 'none',
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={recaptchaKey}
              onChange={token => setValues({ ...values, ['g_recaptcha_token']: token })}
            />
            <FormHelperText
              error
              sx={{
                display: isCaptchaValid() ? 'none' : 'block',
              }}
            >{errors.g_recaptcha_token ? errors.g_recaptcha_token : 'Recaptcha is required'}</FormHelperText>
          </FormControl>
          <LoadingButton
            loading={fetching}
            disabled={!isFormValid()}
            title={isFormValid() ? '' : 'Fix errors'}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >Sign In</LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="/password-reset/" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetching: state.auth.fetching,
    errors: state.auth.errors,
    isCaptcha: state.auth.isCaptcha,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (email: string, password: string, g_recaptcha_token: any) => dispatch(AuthActions.login(email, password, g_recaptcha_token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
