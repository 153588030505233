import { DAY_IN_MS, DAY_LIMIT_FOR_RANGE } from './constants'

export const getBasename = () => {
  return `/${process.env.PUBLIC_URL?.split('/').pop()}`;
};

export const isNavActive = (to: string, exact: boolean, path: string) => {
  return exact ? path === to : path.startsWith(to)
}

export const isValidMail = (mail: string) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(mail?.toLowerCase());
}

/**
 * Check whether the password is between 8 and 64 characters long
 */
export const isValidPassword = (password: string) => {
  return password.length >= 8 && password.length <= 64
}

export const buildUrls = (base: string, action: any) => {
  let data: any = {}

  if (action.pageSize) {
    data['limit'] = action.pageSize
    if (action.page) {
      data['offset'] = action.page * action.pageSize
    }
  }

  if (action.sort && action.sort.length) {
    let sortingParams = ''

    action.sort.forEach((item: any) => {
      if (sortingParams.length)
        sortingParams += ','
      if (item.sort === 'desc')
        sortingParams += '-'
      sortingParams += item.field
    })

    data['ordering'] = sortingParams
  }

  if (action.filter && action.filter.items) {
    action.filter.items.forEach((filter: any) => {
      if (filter.value === undefined) return
      let key = filter.columnField

      if (filter.operatorValue === 'contains') {
        key += '__'
      }
      if (filter.operatorValue === '>' || filter.operatorValue === 'after') {
        key += '__gt'
      }
      if (filter.operatorValue === '<' || filter.operatorValue === 'before') {
        key += '__lt'
      }
      if (filter.operatorValue === '!=' || filter.operatorValue === 'not') {
        key += '__not'
      }
      if (filter.operatorValue === 'isAnyOf') {
        key += '__in'
      }

      data[key] = filter.value
    })
  }

  return base.replace(/^\/+/, '') + '?' + new URLSearchParams(data)
}

export const classNames = (obj: any) => {
  return Object.keys(obj).filter(key => obj[key]).join(' ')
}

export const isEmpty = (obj: Record<string, any>): boolean => Object.keys(obj).length === 0

export const validateDateRange = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) return 'Required'
  let dif_days = (endDate.getTime() - startDate.getTime()) / DAY_IN_MS
  if (dif_days < 0) return 'Start must be before end'
  if (dif_days > DAY_LIMIT_FOR_RANGE) return 'Too big range'
  return ''
}

export const objectFirst = (obj: Object) => {
  const keys = Object.keys(obj)
  // @ts-ignore
  return obj[keys[0]]
}

export const objectLast = (obj: Object) => {
  const keys = Object.keys(obj)
  // @ts-ignore
  return obj[keys[keys.length - 1]]
}

export const objectLength = (obj: Object) => Object.keys(obj).length

export const objectMap = (obj: Object, fn: Function) => Object.fromEntries(Object.entries(obj).map(
  ([k, v], i) => [k, fn(v, k, i)]
))

export const slice = (text:string, length: number, postfix: string = '...') => {
  if (text.length <= length) return text
  return text.slice(0, length) + postfix;
}
