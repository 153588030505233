import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { iSimpleUser } from './authRedux'

export const TYPE_VOTING = 1
export const TYPE_MANAGER_DISPUTE = 2
export const TYPE_ANNOTATOR_DISPUTE = 3
export const TYPE_PAYOUT = 4

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateCount: ['count'],
  updateList: ['list'],
  updateFetching: ['fetching'],

  createTransactions: ['data'],
  loadTransactions: ['page', 'pageSize', 'sort', 'filter'],
})

export const TransactionsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export interface iCreateTransaction {
  type: number
  user_id: number
  compensation: number
}

export interface iTransaction extends iCreateTransaction {
  id?: number
  user?: iSimpleUser
  match_count?: number
  match_count_total?: number
  count_total?: number
  match_bonus?: number
  quality?: number
  progressive_quantity_bonus?: number
  created_at?: string
}

type iTransactionsState = {
  count: number,
  list: iTransaction[],
  fetching: boolean,
}

export const INITIAL_STATE = Immutable({
  count: 0,
  list: [],
  fetching: false,
})

/* ------------- Reducers ------------- */

export const updateCount = (state: any, { count }: any) =>
  state.merge({ count })

export const updateList = (state: any, { list }: any) =>
  state.merge({ list })

export const updateFetching = (state: any, { fetching }: any) =>
  state.merge({ fetching })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_COUNT]: updateCount,
  [Types.UPDATE_LIST]: updateList,
  [Types.UPDATE_FETCHING]: updateFetching,
})
