import React, { useMemo } from 'react'
import { connect } from 'react-redux'

import ReactApexChart from 'react-apexcharts'
import { DataGrid } from '@mui/x-data-grid'
import { Typography } from '@mui/material'

import { iLayer } from '../../redux/bucketsRedux'
import { objectMap } from '../../utils/functions'

interface iProps {
  statistic: [iLayer]
  fetching: boolean
}

function DeduplicationStatTotal(props: iProps) {
  const { statistic, fetching } = props

  const chart = useMemo(() => {
    let categories: string[] = []
    let adm_data: number[] = []
    let bp_data: number[] = []
    let annotators_data: number[] = []
    let total_data: number[] = []

    objectMap(statistic, (row: iLayer) => {
      categories.push(row.date)
      const adm = +(row.layer_adm_process_results_finish || 0) / +(row.layer_adm_find_candidates_start || 1) * 100
      adm_data.push(Math.round(adm * 100) / 100)
      const bp = +(row.layer_bp_process_results_finish || 0) / +(row.layer_bp_find_candidates_start || 1) * 100
      bp_data.push(Math.round(bp * 100) / 100)
      const annotators = +(row.layer_annotators_process_results_finish || 0) / +(row.layer_annotators_find_candidates_start || 1) * 100
      annotators_data.push(Math.round(annotators * 100) / 100)
      const total = +(row.layer_final_process_results_finish || 0) / +(row.send_to_orchestrator_success || 1) * 100
      total_data.push(Math.round(total * 100) / 100)
    })

    return {
      series: [
        {name: 'Adm %', data: adm_data},
        {name: 'BP %', data: bp_data},
        {name: 'Annotators %', data: annotators_data},
        {name: 'Total %', data: total_data},
      ],
      options: {
        chart: {
          toolbar: {
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            }
          }
        },
        xaxis: {
          categories,
        }
      },
    }
  }, [statistic])

  return (
    <>
      <ReactApexChart options={chart.options} series={chart.series} type="line" height={350} />
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'asc' }],
          },
        }}
        getRowId={(row) => row.date}
        rows={statistic}
        columns={[
          {
            field: 'date',
            headerName: 'Date',
            type: 'date',
            width: 150,
            filterable: false,
          },
          {
            field: 'layer_adm_find_candidates_start',
            headerName: 'ADM Start',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_adm_process_results_finish',
            headerName: 'ADM Finished',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_bp_find_candidates_start',
            headerName: 'BP Start',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_bp_process_results_finish',
            headerName: 'BP Finished',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_annotators_find_candidates_start',
            headerName: 'Annotators Start',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_annotators_process_results_finish',
            headerName: 'Annotators Finished',
            type: 'number',
            width: 150,
          },
          {
            field: 'send_to_orchestrator_success',
            headerName: 'Total Start',
            type: 'number',
            width: 150,
          },
          {
            field: 'layer_final_process_results_finish',
            headerName: 'Total Finished',
            type: 'number',
            width: 150,
          },
        ]}
        sortingMode="client"
        filterMode="client"
        loading={fetching}
        autoHeight={true}
        pageSize={25}
      />
      <Typography variant="body2" color="text.secondary">
        <i>Select events by data range, grouped by day. Show how much uniquier events create by day.</i>
        <br/>
        <i>If in some day finish <b>N</b> request, the biggest part of them has been created before. Usually we have some queue in annotators service.</i>
      </Typography>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    statistic: state.buckets.statistic,
    fetching: state.buckets.fetching,
  }
}

export default connect(mapStateToProps)(DeduplicationStatTotal)
