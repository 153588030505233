import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {CustomBlacklistingDialogProps} from './Interfaces';

function CustomBlacklistingDialog({ open, handleClose, apiResponse, selectedSimilarImages }: CustomBlacklistingDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Blacklisting results</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {apiResponse && apiResponse.data.success === selectedSimilarImages.length ? (
            <p>All images were added successfully!</p>
          ) : (
            apiResponse && apiResponse.data.success !== 0 && (
              <p>
                Successfully added {apiResponse.data.success} out of {selectedSimilarImages.length} images
              </p>
            )
          )}

          {apiResponse && apiResponse.data.failed.length > 0 && (
            <div>
              <p>Failed to add:</p>
              <ol>
                {apiResponse.data.failed.map((failedItem:any, index:any) => (
                  <div key={index}>
                    <li>
                      <a href={failedItem.thumbnail} target="_blank" rel="noopener noreferrer">
                        {failedItem.phash}: {failedItem.error}
                      </a>
                    </li>
                  </div>
                ))}
              </ol>
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomBlacklistingDialog;
