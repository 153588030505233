import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { GridFilterModel } from '@mui/x-data-grid'

import API from '../services/api'
import { buildUrls } from '../utils/functions'
import { iPerformance } from './UsePerformanceStore'
import { iSimpleUser } from '../redux/authRedux'

export interface iMatchingPerformance {
  user: iSimpleUser
  list: { [key: number]: iPerformance }
}

interface UseMatchingPerformanceState {
  loading: boolean
  list: iMatchingPerformance[]

  load: (filter: GridFilterModel) => void
}


export const UseMatchingPerformanceStore = create<UseMatchingPerformanceState>()(
  devtools(
    persist(
      (set, get) => ({
        loading: false,
        list: [],

        load: async(filter) => {
          set({ loading: true })
          try {
            const response = await API.get(buildUrls(`voting/statistic/performance/`, { filter }))

            if (response.ok) {
              set({ list: response.data })
            } else {
              set({ list: [] })
              console.error('!response.ok', response)
            }
          } catch (e) {
            console.log('load matching performance:', e)
          } finally {
            set({ loading: false })
          }
        },
      }),
      {
        name: 'matching-performance-store',
      }
    )
  )
)
